import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { GerenciarFuncionarioInterface } from "../../../../interfaces/GetFuncionarioInterface";
import api from "../../../../services/api";
import { dialogBox } from "../../../../template/utils/dialogBox";
import { parseDate2 } from "../../../../template/utils/parser";
import { Container } from "../../styles";
import { CustomInput } from "./../../../../template/styles/styles";
import { CPFMask } from "./../../../../template/utils/masks";
import { FormContainer } from "./styles";

interface IProps {
  toggle: () => void;
  loadGerenciarFuncionario: () => void;
  setIsLoading: (value: boolean) => void;
}
const CadastrarForm: React.FC<IProps> = (props) => {
  const {
    handleSubmit,
    register,
    errors,
    control,
    setError,
    getValues,
  } = useForm({ mode: "onSubmit" });
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const onSubmit = async (data: any) => {
    props.setIsLoading(true);
    data.documento.tipo = 1;
    const documentoNumero = data.documento.numero.replace(/\D/g, "");
    data.documento.numero = documentoNumero;
    data.usuario.username = documentoNumero;
    const gerenciarfuncionario: GerenciarFuncionarioInterface = data;

    // Cadastra novo usuário
    api
      .post(`/funcionarios-laticinios/`, gerenciarfuncionario)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Funcionário cadastrado com sucesso!`);
        // Atualiza a tabela
        props.loadGerenciarFuncionario();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.numero) {
          setError("documento.numero", {
            type: "manual",
            message: error.response.data.numero[0],
          });
        } else if (error.response.data?.documento?.numero) {
          notify("error", `${error.response.data.documento.numero[0]}`);
          setError("documento.numero", {
            type: "manual",
            message: error.response.data.documento.numero[0],
          });
        } else if (error.response.data?.documento?.cpf) {
          notify("error", `${error.response.data.documento.cpf[0]}`);
          setError("documento.numero", {
            type: "manual",
            message: error.response.data.documento.cpf[0],
          });
        } else if (error.response.data?.usuario?.email) {
          notify("error", `${error.response.data.usuario.email[0]}`);
          setError("usuario.email", {
            type: "manual",
            message: error.response.data.usuario.email[0],
          });
        } else {
          notify(
            "error",
            `Ocorreu um erro no cadastro, verifique o formulário`
          );
        }
      })
      .finally(() => props.setIsLoading(false));
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.nome">Nome</label>
              <input
                className={
                  errors.pessoa?.nome
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Nome completo da pessoa"
                name="pessoa.nome"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.pessoa?.nome?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.nome?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="documento">CPF</label>
              <Controller
                className={
                  errors.documento?.numero
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="documento.numero"
                as={InputMask}
                placeholder="Ex.: 999.999.999-99"
                control={control}
                mask={CPFMask.mask}
                maskChar=""
                rules={{
                  required: true,
                  minLength: CPFMask.maxLength,
                }}
              />
              {errors.documento?.numero?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.documento?.numero?.type === "minLength" && (
                <span className="error-message">
                  Campo deve conter 11 dígitos
                </span>
              )}
              {errors.documento?.numero && (
                <span className="error-message">
                  {errors.documento.numero.message}
                </span>
              )}
              {errors.documento?.cpf && (
                <span className="error-message">
                  {errors.documento.cpf.message}
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.data_nascimento">Data de Nascimento</label>
              <input
                type="date"
                className={
                  errors.pessoa?.data_nascimento
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="01/01/2001"
                name="pessoa.data_nascimento"
                ref={register({
                  required: true,
                  validate: (value) => new Date(value) <= new Date(),
                })}
              />
              {errors.pessoa?.data_nascimento?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.data_nascimento?.type === "validate" && (
                <span className="error-message">Inserir data passada</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="pessoa.telefone">Telefone/Celular</label>
              <Controller
                className={
                  errors.pessoa?.telefone
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="pessoa.telefone"
                as={InputMask}
                placeholder="(99)9 9999-9999"
                control={control}
                mask={"(99)9 9999-9999"}
                maskChar=""
                rules={{
                  required: true,
                  minLength: 15,
                }}
              />
              {errors.pessoa?.telefone?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.pessoa?.telefone?.type === "minLength" && (
                <span className="error-message">
                  O campo deve conter 11 dígitos
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                className={
                  errors.usuario?.email
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="exemplo@email.com"
                name="usuario.email"
                ref={register({
                  required: true,
                })}
              />
              {errors.usuario?.email?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.email && (
                <span className="error-message">{errors.email.message}</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="data_expiracao">Data de Expiração</label>
              <input
                type="date"
                className={
                  errors.data_expiracao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                defaultValue={parseDate2(
                  new Date(new Date().setFullYear(new Date().getFullYear() + 2))
                )}
                name="data_expiracao"
                ref={register({
                  required: true,
                  validate: (value) => new Date(value) > new Date(),
                })}
              />
              {errors.data_expiracao?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.data_expiracao?.type === "validate" && (
                <span className="error-message">Inserir data futura</span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="area_atuacao">Área de Atuação</label>
              <input
                type="area_atuacao"
                className={
                  errors.area_atuacao
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder=""
                name="area_atuacao"
                ref={register({
                  required: true,
                })}
              />
              {errors.area_atuacao?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.area_atuacao && <span className="error-message"></span>}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="cargo">Cargo</label>
              <input
                type="cargo"
                className={
                  errors.cargo ? "CustomInput error-input" : "CustomInput"
                }
                placeholder=""
                name="cargo"
                ref={register({
                  required: true,
                })}
              />
              {errors.cargo?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.cargo && <span className="error-message"></span>}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="password">Senha</label>
              <input
                type="password"
                className={
                  errors.usuario?.password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Mínimo 6 digitos"
                name="usuario.password"
                ref={register({
                  required: true,
                  minLength: 6,
                })}
              />
              {errors.usuario?.password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.usuario?.password?.type === "minLength" && (
                <span className="error-message">
                  A senha deve ter no mínimo 6 dígitos
                </span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="check_password">Repetir senha</label>
              <input
                type="password"
                className={
                  errors.check_password
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                placeholder="Minimo 6 digitos"
                name="check_password"
                ref={register({
                  validate: (value) => value === getValues("usuario.password"),
                })}
              />
              {errors.check_password?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.check_password?.type === "validate" && (
                <span className="error-message">
                  A senha da confirmação não é igual à senha digitada
                </span>
              )}
            </CustomInput>
          </div>
        </div>
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
