import styled from "styled-components";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import colors from "../../styles/colors";

export const CustomPagination = styled(Pagination)`
  PaginationLink:first-child {
    border-radius: 8px 8px 0 0;
  }
  PaginationLink:last-child {
    border-radius: 0 0 8px 8px;
  }
`;

export const CustomPaginationItem = styled(PaginationItem)`
  .active {
    color: ${colors.light};
    background-color: ${colors.blueOfficial};
  }
  .active:hover {
    background-color: ${colors.bluePLP};
  }
`;

export const CustomPaginationLink = styled(PaginationLink)`
  height: 39px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  color: ${colors.ultraDarkGrey};
  :focus {
    outline: none;
    box-shadow: none;
  }
`;
