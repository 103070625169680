import React, { useMemo } from "react";

import {
  TableButton,
  CustomGoSearch,
  CustomBsPencil,
  TableRowContainer,
} from "./styles";

import { SelectButton } from "../../styles";

interface TableRowProps {
  row?: any;
  functions?: any[];
  hasSelection: boolean;
  isMultiple?: boolean;
  selectOne?: (obj: any) => void;
  selectMultiple?: (obj: any) => void;
  selectedElement?: any;
  iconName?: string;
}

const iconMap: any = {
  CustomGoSearch: CustomGoSearch,
  CustomBsPencil: CustomBsPencil,
};

const selectIcon = (iconName: string) => {
  if (iconName) {
    return React.createElement(iconMap[iconName], {
      className: "icon-button-table",
    });
  } else {
    return React.createElement(iconMap["CustomGoSearch"], {
      className: "icon-button-table",
    });
  }
};

const TableRow: React.FC<TableRowProps> = (props) => {
  const {
    hasSelection,
    row,
    functions,
    isMultiple,
    selectOne,
    selectMultiple,
    selectedElement,
    iconName,
  } = props;

  const memoizedSelectIcon = useMemo(() => selectIcon(iconName || ""), [
    iconName,
  ]);

  const changeMultiple = (row: any) => {
    if (selectMultiple) {
      selectMultiple(row);
    }
  };

  const changeOne = (row: any) => {
    if (selectOne) {
      selectOne(row);
    }
  };

  const objectToArray = (obj: any) => {
    let values = [];
    for (let key in obj) {
      if (key !== "id") {
        values.push(obj[key]);
      }
    }
    return values;
  };

  return (
    <TableRowContainer
      isSelected={selectedElement && selectedElement.isSelected}
    >
      {isMultiple
        ? hasSelection && (
            <th>
              {selectedElement && selectedElement.isSelected ? (
                <SelectButton onClick={() => changeMultiple(row)}>
                  x
                </SelectButton>
              ) : (
                <SelectButton onClick={() => changeMultiple(row)} />
              )}
            </th>
          )
        : hasSelection && (
            <th>
              {selectedElement && selectedElement.isSelected ? (
                <SelectButton onClick={() => changeOne(row)}>x</SelectButton>
              ) : (
                <SelectButton onClick={() => changeOne(row)} />
              )}
            </th>
          )}
      {objectToArray(row).map((value, idx) => (
        <td key={idx}>
          {" "}
          {typeof value === "boolean" ? (value ? "SIM" : "NÃO") : value}
        </td>
      ))}
      {functions &&
        functions.map((func) => (
          <td className="action-cell" key={func.name}>
            <TableButton onClick={() => func.action(row)}>
              {memoizedSelectIcon}
              {func.name}
            </TableButton>
          </td>
        ))}
    </TableRowContainer>
  );
};

export default TableRow;
