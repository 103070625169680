import React, { Suspense } from "react";

import { Container } from "./styles";

interface IProps {
  children?: React.ReactNode;
}

const Content: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    </Container>
  );
};

export default React.memo(Content);
