import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;
