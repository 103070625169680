import styled from "styled-components";
import colors from "../../styles/colors";
import { GoSearch } from "react-icons/go";
import { AiOutlineReload } from "react-icons/ai";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 5px;
  margin-left: 5px;
`;

export const SearchForm = styled.form`
  display: flex;
  input {
    width: 100%;
    min-width: 50px;
    padding: 5px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 5px;
    margin-right: 5px;
    outline: none;
    color: #212529;
  }
  input:focus {
    color: #00b4cc;
  }
`;

export const SearchButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  text-align: center;
  width: 2.5rem;
  height: 100%;
  color: ${colors.blueOfficial};
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  :hover,
  :focus {
    color: ${colors.light};
    background-color: ${colors.blueOfficial};
  }
`;

export const CustomGoSearch = styled(GoSearch)`
  font-size: 18px;
`;

export const ResetButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  text-align: center;
  width: 2rem;
  height: 100%;
  color: ${colors.blueOfficial};
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  :hover,
  :focus {
    color: ${colors.light};
    background-color: ${colors.blueOfficial};
  }
`;

export const CustomReload = styled(AiOutlineReload)`
  font-size: 18px;
`;