import styled from "styled-components";
import colors from "template/styles/colors";
import { AiOutlineBarChart } from "react-icons/ai";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: gray;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    justify-content: flext-start;
    align-items: center;
  }

  .space {
    margin-left: 20px;
    width: 100%;
  }

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  .searchBar {
    margin-right: 9px;
  }

  /* .space {
    width: 100%;
    padding: 3px;
    display: flex;
    justify-content: flex-end;
  } */
`;

export const IconRowTitle = styled(AiOutlineBarChart)`
  margin-right: 5px;
  font-size: 20px;
`;

export const CardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: white;

  .containerFooterTable {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const TelaCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
`;
