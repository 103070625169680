import React, { ReactElement } from "react";
import { CardContainer, CardHeader, CardBody } from "./styles";

interface IProps {
  title: string;
  width?: string;
  heigth?: string;
  children: ReactElement;
}

const Card = ({ title, width, heigth, children }: IProps) => {
  return (
    <CardContainer width={width} height={heigth}>
      <CardHeader>{title}</CardHeader>
      <CardBody>{children}</CardBody>
    </CardContainer>
  );
};

export default Card;
