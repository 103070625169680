import React, { useState } from "react";
import Modal from "../../../../template/components/Modal";

import {
  ButtonSave,
  ButtonClose,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";
import DesabilitarForm from "../DesabilitarForm";
import { createGlobalStyle } from "styled-components";
import { LoadingComponent } from "pages/components/Loading/styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  desabilitarProdutor: (row: any, data: any) => Promise<void>;
  isLoading: boolean;
}

interface IStyle {
  modalOpen: boolean;
}

const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => (props.modalOpen ? "auto" : "auto")}!important;
	}
`;

const DesabilitarModal = ({
  row,
  isOpen,
  toggle,
  desabilitarProdutor,
  isLoading,
}: IProps) => {
  const [isLoadingDesabilitar, setIsLoadingDesabilitar] = useState(false);

  const handleClose = () => {
    if (isLoadingDesabilitar) return;
    toggle();
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose}>
          <CustomAiOutlineClose /> Cancelar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="desabilitarForm"
          disabled={isLoadingDesabilitar || isLoading}
        >
          {isLoadingDesabilitar ? (
            <LoadingComponent height="10px" width="10px" />
          ) : (
            <CustomAiOutlineCheck />
          )}{" "}
          <span>Desabilitar</span>
        </ButtonSave>
      </React.Fragment>
    );
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      modalTitle={`Desabilitar Produtor`}
      footerContent={footerButtons()}
      fixed={true}
    >
      <GlobalModalStyle modalOpen={isOpen} />
      <DesabilitarForm
        setIsLoading={setIsLoadingDesabilitar}
        toggle={handleClose}
        row={row}
        desabilitarProdutor={desabilitarProdutor}
      />
    </Modal>
  ) : (
    <div />
  );
};

export default DesabilitarModal;
