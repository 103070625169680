import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.footer`
  background-color: ${colors.ultraLightGrey};
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 35px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.darkGrey};
  color: ${colors.ultraDarkGrey};
  font-size: 11px;
`;

export const FooterItem = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p,
  b {
    margin: 0;
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img:last-of-type {
    margin-left: 5px;
  }
`;
