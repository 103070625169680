import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";

import { FormContainer } from "./styles";
import { CustomInput } from "../../../styles/styles";
import { Container } from "../styles";
import { dialogBox } from "../../../utils/dialogBox";
import api from "../../../../services/api";
import { CPFMask } from "../../../utils/masks";
import { parseCPF, parseDate2 } from "../../../utils/parser";
import { UsuarioGeralInterface } from "../../../utils/types";


interface IProps {
  row: UsuarioGeralInterface;
  toggle: () => void;
  loadUsuarios: () => void;
}

const EditForm: React.FC<IProps> = (props) => {
  const { row } = props;
  const { handleSubmit, register, getValues, errors, control, setError } =
    useForm({ mode: "onSubmit" });

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const GetFormatedDate = (date: Date) => {
    let temp = new Date(date);
    const FormatOptions = {
      minimumIntegerDigits: 2,
    };
    const Locale = "pt-BR";
    const AddLeadingZeros = (date: number) => {
      return date.toLocaleString(Locale, FormatOptions);
    };

    let formated = `${temp.getUTCFullYear()}-${AddLeadingZeros(
      temp.getUTCMonth() + 1
    )}-${AddLeadingZeros(temp.getUTCDate())}`;
    return formated;
  };

  const onSubmit = async (data: any) => {
    let btn = document.getElementById("editbtn")
    if(btn) btn.setAttribute('disabled', '');
    data.documento.numero = data.documento.numero.replace(/[.-]/g, "").trim();
    data.username = data.documento.numero;
    
    if (data.matricula === "") {
      data.matricula = null;
    }
    const usuario = data;
    usuario.documento.tipo = 1;
    usuario.is_gestor_estadual = data.is_gestor_estadual;
    usuario.is_gestor_local = data.is_gestor_local;
    usuario.is_colaborador_ponto = data.is_colaborador_ponto;
    usuario.is_gestor_laticinio =  data.is_gestor_laticinio;
    //Verificação da necessidade de deletar campos do JSON
    if (usuario.is_gestor_estadual === false) delete usuario.matricula;
    if (usuario.is_gestor_local === false) delete usuario.entidade;
    if (usuario.password === "") delete usuario.password;
    delete usuario.check_password;
    usuario.pessoa.id = row.pessoa?.id;
    usuario.id_user = row.id_user;
    // Edita as informações do gestor
    api
      .patch(`/usuarios/${row.id_user}/`, usuario)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Usuário editado com sucesso!`);
        // Atualiza a tabela
        props.loadUsuarios();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        if (error.response.data.numero) {
          setError("documento.numero", {
            type: "manual",
            message: error.response.data.numero[0],
          });
        } else if (error.response.data.cpf) {
          notify("error", `${error.response.data.cpf[0]}`);
        } else if (error.response.data.matricula) {
          setError("matricula", {
            type: "manual",
            message: error.response.data.matricula,
          });
        } else if (error.response.data?.usuario?.email) {
          setError("email", {
            type: "manual",
            message: error.response.data.usuario.email[0],
          });
        } else if (error.response.data?.usuario?.username) {
          setError("username", {
            type: "manual",
            message: error.response.data.usuario.username[0],
          });
        } else {
          notify("error", `Ocorreu um erro na edição, verifique o formulário`);
        }
      });
  };

  return (
    row && (
      <Container>
        <FormContainer id="editForm" onSubmit={handleSubmit(onSubmit)}>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.nome">Nome</label>
                <input
                  className={
                    errors.pessoa?.nome
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  placeholder="Nome completo da pessoa"
                  name="pessoa.nome"
                  defaultValue={row.pessoa?.nome}
                  ref={register({
                    required: true,
                    maxLength: 60,
                  })}
                />
                {errors.pessoa?.nome?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.pessoa?.nome?.type === "maxLength" && (
                  <span className="error-message">
                    Certifique-se de que esse campo não tenha mais que 60
                    caracteres
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.data_nascimento">Nascimento</label>
                <input
                  type="date"
                  className={
                    errors.pessoa?.data_nascimento
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  placeholder="01/01/2001"
                  name="pessoa.data_nascimento"
                  defaultValue={
                    row &&
                    row.pessoa &&
                    row.pessoa.data_nascimento &&
                    GetFormatedDate(row.pessoa.data_nascimento)
                  }
                  ref={register({
                    required: true,
                    validate: (value) => value <= parseDate2(new Date()),
                  })}
                />
                {errors.pessoa?.data_nascimento?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.pessoa?.data_nascimento?.type === "validate" && (
                  <span className="error-message">Inserir data passada</span>
                )}
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="pessoa.telefone">Telefone (Opcional)</label>
                <Controller
                  className={
                    errors.pessoa?.telefone
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  as={InputMask}
                  name="pessoa.telefone"
                  control={control}
                  mask="(99)99999-9999"
                  maskChar=""
                  placeholder="(99)99999-9999"
                  defaultValue={row.pessoa?.telefone}
                  rules={{ minLength: 14 }}
                />
                {errors.pessoa?.telefone?.type === "minLength" && (
                  <span className="error-message">
                    Se preenchido, campo deve conter 11 dígitos
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className={
                    errors.email ? "CustomInput error-input" : "CustomInput"
                  }
                  placeholder="exemplo@email.com"
                  name="email"
                  defaultValue={row.email}
                  ref={register({
                    required: true,
                  })}
                />
                {errors.email?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.email && (
                  <span className="error-message">{errors.email.message}</span>
                )}
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="documento" className="label-disabled">CPF</label>
                <Controller
                  className={
                    errors.documento?.numero
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name="documento.numero"
                  as={InputMask}
                  control={control}
                  mask={CPFMask.mask}
                  maskChar=""
                  defaultValue={parseCPF(row.documento.numero)}
                  rules={{
                    required: true,
                    minLength: CPFMask.maxLength,
                  }}
                  disabled
                />
                {errors.documento?.numero?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.documento?.numero?.type === "minLength" && (
                  <span className="error-message">
                    Campo deve conter 11 dígitos
                  </span>
                )}
                {errors.documento?.numero && (
                  <span className="error-message">
                    {errors.documento.numero.message}
                  </span>
                )}
                {errors.documento?.cpf && (
                  <span className="error-message">
                    {errors.documento.cpf.message}
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="data_expiracao" className="label-disabled">Data de expiração</label>
                <input
                  type="date"
                  className={
                    errors.data_expiracao
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  name="data_expiracao"
                  defaultValue={
                    row &&
                    row.data_expiracao &&
                    GetFormatedDate(row.data_expiracao)
                  }
                  ref={register({
                    required: true,
                    validate: (value) => value > parseDate2(new Date()),
                  })}
                  disabled
                />
                {errors.data_expiracao?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.data_expiracao?.type === "validate" && (
                  <span className="error-message">Inserir data futura</span>
                )}
              </CustomInput>
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          ></div>
          <div
            className="row"
            style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="password">Senha</label>
                <input
                  type="password"
                  className={
                    errors.password ? "CustomInput error-input" : "CustomInput"
                  }
                  placeholder="Mínimo 6 digitos"
                  name="password"
                  ref={register({
                    minLength: 6,
                  })}
                />
                {errors.password?.type === "minLength" && (
                  <span className="error-message">
                    A senha deve ter no mínimo 6 dígitos
                  </span>
                )}
              </CustomInput>
            </div>
            <div className="col-sm">
              <CustomInput>
                <label htmlFor="check_password">Repetir senha</label>
                <input
                  type="password"
                  className={
                    errors.check_password
                      ? "CustomInput error-input"
                      : "CustomInput"
                  }
                  placeholder="Minimo 6 digitos"
                  name="check_password"
                  ref={register({
                    minLength: 6,
                    validate: (value) => value === getValues("password"),
                  })}
                />
                {errors.check_password?.type === "required" && (
                  <span className="error-message">Campo obrigatório</span>
                )}
                {errors.check_password?.type === "validate" && (
                  <span className="error-message">
                    A senha da confirmação não é igual à senha digitada
                  </span>
                )}
                {errors.check_password?.type === "minLength" && (
                  <span className="error-message">
                    A senha deve ter no mínimo 6 dígitos
                  </span>
                )}
              </CustomInput>
            </div> 
          </div>          
        </FormContainer>
      </Container>
    )
  );
};

export default EditForm;
