import React, { useState } from "react";
import Loading from "pages/components/Loading";
import Modal from "../../../../template/components/Modal";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
  ButtonContentContainer,
} from "./styles";
import api from "../../../../services/api";
import { dialogBox } from "template/utils/dialogBox";
import { LoadingComponent } from "../../../components/Loading/styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadRotas: () => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

const notify = (type: string, message: string) => dialogBox(type, message);

const HabilitarModal = ({
  row,
  isOpen,
  toggle,
  loadRotas,
  isLoading,
}: IProps) => {
  const [isLoadingHabilitar, setIsLoadingHabilitar] = useState(false);

  const handleHabilitarRota = () => {
    setIsLoadingHabilitar(true);
    const updated = {
      ativo: true,
    };
    api
      .patch(`rotas-entrega/${row.id}/`, updated)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Rota ativada com sucesso!`);
        // Atualiza a tabela
        loadRotas();
        setIsLoadingHabilitar(false);
        // Fecha o modal somente após a conclusão da requisição
        toggle();
      })
      .catch(function (error) {
        // Handle error here if the request fails
        console.error("Erro na requisição: ", error);
        setIsLoadingHabilitar(false);
      });
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose} disabled={isLoadingHabilitar}>
          <CustomAiOutlineClose /> Não
        </ButtonClose>
        <ButtonSave
          type="submit"
          onClick={handleHabilitarRota}
          disabled={isLoadingHabilitar || isLoading}
        >
          <ButtonContentContainer>
            {isLoadingHabilitar ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Sim</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  const handleClose = () => {
    if (!isLoadingHabilitar) {
      toggle();
    }
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={`Habilitar Rota de Entrega`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      {isLoading ? (
        <Loading
          heigthContainer="30px"
          heightLoading="20px"
          widthLoading="20px"
        />
      ) : (
        <h6>Você deseja habilitar a rota {row.nome_rota}?</h6>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default HabilitarModal;
