import React from "react";

import {
  Container,
  MainContainerHeader,
  MainContainerBody,
  CustomIcon,
  BrasaoRN,
} from "./styles";

import BreadCrumb from "../BreadCrumb";

import brasaoRN from "../../assets/Brasão_do_Rio_Grande_do_Norte.png";

interface IProps {
  iconPage: React.ReactNode;
  titlePage: string;
  children: React.ReactNode;
  routePathsDict?: {[path:string]: string} | undefined;
}

const MainContainer: React.FC<IProps> = ({ iconPage, titlePage, children, routePathsDict }) => {
  return (
    <React.Fragment>
      <BreadCrumb paths={routePathsDict} />
      <MainContainerHeader>
        <CustomIcon>{iconPage}</CustomIcon>
        {titlePage}
      </MainContainerHeader>
      <Container>
        <MainContainerBody>{children}</MainContainerBody>
      </Container>
      <BrasaoRN src={brasaoRN} />
    </React.Fragment>
  );
};

export default MainContainer;
