import React, { useEffect, useState } from "react";
import Header from "../Header";
import Content from "../Content";
import Sidebar from "../Sidebar";
import Footer from "../Footer";

import { Container } from "./styles";

interface IProps {
  children: React.ReactNode;
  logoName?: string;
}

const Layout: React.FC<IProps> = ({ children, logoName }) => {
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  useEffect(() => {
    const { innerWidth } = window;
    setSidebarOpen(innerWidth > 700);
  }, []);
  return (
    <React.Fragment>
      <Header toggle={toggleSidebar} />
      <Sidebar isOpen={sidebarIsOpen} logoName={logoName} />
      <Container>
        <Content>{children}</Content>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

export default Layout;
