import styled from "styled-components";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

import colors from "../../styles/colors";

interface IProps {
  active?: boolean;
}

export const CustomBreadcrumb = styled(Breadcrumb)`
  width: 100%;
  max-width: 1132px;
  margin-bottom: 20px;
  .breadcrumb {
    background-color: #fff;
    padding: 0;
    margin-top: 13px;
    border-radius: 8px;
    box-shadow: 1px 2px 10px ${colors.darkGrey};
  }
`;

export const CustomBreadcrumbItem = styled(BreadcrumbItem)`
  padding: 5px 8px;
  color: ${colors.darkGrey};
  cursor: pointer;
  :hover {
    color: ${colors.blueOfficial};
  }
`;

export const CustomLink = styled(Link)<IProps>`
  && {
    margin-left: 0;
    color: ${(props) => (props.active? colors.blueOfficial : colors.darkGrey)};
    text-decoration: none;
    text-transform: Capitalize;
    :hover {
      color: ${colors.blueOfficial};
    }
  }
`;
