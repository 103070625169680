import React from "react";
import CustomSelect from "./styles";
interface IProps {
  quantidade: number;
  onChangeHandler: (option: number) => void;
}

const SelectRange: React.FC<IProps> = ({ quantidade, onChangeHandler }) => {
  const inputs = () => {
    let items = [];
    items.push(<option value="-1" key={-1}>...</option>);
    for (let i = 0; i < quantidade; i++) {
      items.push(<option key={i+1} value={i + 1}>{i + 1}</option>);
    }
    return items;
  };
  return (
    <CustomSelect
      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        let value = parseInt(event.target.value);
        if (value !== -1) {
          onChangeHandler(value);
          event.target.selectedIndex = 0;
        }
      }}
    >
      {inputs()}
    </CustomSelect>
  );
};

export default SelectRange;
