import styled from "styled-components";
import colors from "./../../../../template/styles/colors";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }

  .CheckBoxContainer-0 {
    height: auto;
    margin-top: 2rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-0 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-0 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .CheckBoxContainer-1 {
    height: auto;
    margin-top: 0.5rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-1 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-1 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .CheckBoxContainer-2 {
    height: auto;
    margin-top: 0.5rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    vertical-align: middle;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-2 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-2 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .white-check {
    color: white;
    width: 100px;
    height: 30px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    float: right;
  }
`;
