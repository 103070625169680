import styled from "styled-components";

import colors from "../../styles/colors";

export const CustomIcon = styled.span`
  font-size: 20px;
  margin-right: 13px;
`;

export const Container = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: fit-content;
  max-width: 1132px;
  background-color: white;
  border: 1px solid ${colors.darkGrey};
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 1px 2px 10px ${colors.darkGrey};
`;

export const MainContainerHeader = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${colors.ultraDarkGrey};
  height: 46px;
  padding: 16px;
  color: #fff;
  width: 100%;
  max-width: 1132px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 1px 2px 10px ${colors.darkGrey};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const MainContainerBody = styled.div`
  padding: 18px;
`;

export const BrasaoRN = styled.img`
  display: none;
  @media screen and (min-width: 1367px) {
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 47px;
    right: 0;
  }
`;
