export const parseJsonToTableData = (obj: any, keys: string[]) => {
  let newObj: any = { id: obj.id };
  keys.forEach((key: string) => {
    if (key.includes("__")) {
      let keyAndSubKey = key.split("__");
      if (obj[keyAndSubKey[0]]?.nome === "")
        newObj[keyAndSubKey[0]] = "Não cadastrado";
      else
        newObj[keyAndSubKey[0]] = obj[keyAndSubKey[0]][keyAndSubKey[1]];
    } else {
      switch (key) {
        case "tipo_refeicao":
          newObj[key] = parseTipoRefeicao(obj[key]);
          break;
        case "isento":
          newObj[key] = parseIsento(obj[key]);
          break;
        case "cpf":
          newObj[key] = parseCPF(obj[key]);
          break;
        case "nis":
          newObj[key] = parseNIS(obj[key]);
          break;
        case "data_nascimento":
          obj[key] !== null ? (newObj[key] = parseDate(obj[key])) : (newObj[key] = "Não cadastrado");
          break;
        default:
          obj[key] !== null ? (newObj[key] = obj[key]) : (newObj[key] = "Não cadastrado");
          break;
      }
    }
    if (key === "data") {
      newObj[key] = parseData(obj[key]);
    }
  });
  return newObj;
};

export const parseJsonToTableDataList = (objs: any[], keys: string[]) => {
  let newObjs: any[] = [];
  objs.forEach((obj) => {
    newObjs.push(parseJsonToTableData(obj, keys));
  });
  return newObjs;
};

export const parseCPF = (cpf: string | undefined) => {
  let parsedCPF;
  if (cpf) {
    parsedCPF = `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9
    )}-${cpf.slice(9)}`;
  } else {
    parsedCPF = "Não cadastrado";
  }
  return parsedCPF;
};

export const parseNIS = (nis: string | undefined) => {
  let parsedNIS;
  if (nis) {
    parsedNIS = `${nis.slice(0, 3)}.${nis.slice(3, 8)}.${nis.slice(
      8,
      10
    )}-${nis.slice(10)}`;
  } else {
    parsedNIS = "Não cadastrado";
  }
  return parsedNIS;
};

export const parseCNPJ = (cnpj: string | undefined) => {
  let parsedCNPJ;
  if (cnpj) {
    parsedCNPJ = `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(
      5,
      8
    )}/${cnpj.slice(8, 12)}-${cnpj.slice(12)}`;
  } else {
    parsedCNPJ = "Não cadastrado";
  }
  return parsedCNPJ;
};

export const parseTipoRefeicao = (value: number) => {
  if (value === 1) {
    return "CAFÉ DA MANHÃ";
  } else if (value === 2) {
    return "ALMOÇO";
  } else if (value === 3) {
    return "JANTAR";
  }
};

export const parseHours = (value: string) => {
  let dateArray = value.split(":");
  return `${dateArray[0]}:${dateArray[1]}`;
};

export const parseDate = (value: string) => {
  let dateArray = value.split("-");
  return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
};

export const parseDate2 = (date: Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const parseIsento = (value: boolean) => {
  return value ? "SIM" : "NÃO";
};

export const parseData = (value: string) => {
  return (
    new Date(value).toLocaleDateString() +
    "\n" +
    new Date(value).toLocaleTimeString()
  );
};

export const parseTelefone = (tel: string | undefined) => {
  let parsedTel;
  let telefone = tel?.replace(/\D/g, "");
  if (telefone) {
    if (telefone.length === 0) {
      parsedTel = "";
    } else if (telefone.length === 1) {
      parsedTel = `(${telefone.slice(0, 1)}`;
    } else if (telefone.length === 2) {
      parsedTel = `(${telefone.slice(0, 2)}`;
    } else if (telefone.length > 2 && telefone.length < 7) {
      parsedTel = `(${telefone.slice(0, 2)})${telefone.slice(2, 10)}`;
    } else if (telefone.length === 7 || (telefone.length > 7 && telefone.length < 10)) {
      parsedTel = `(${telefone.slice(0, 2)})${telefone.slice(2, 6)}-${telefone.slice(6, 10)}`;
    } else if (telefone.length === 10) {
      parsedTel = `(${telefone.slice(0, 2)})${telefone.slice(2, 6)}-${telefone.slice(6, 10)}`;
    } else if (telefone.length >= 11) {
      parsedTel = `(${telefone.slice(0, 2)})${telefone.slice(2, 7)}-${telefone.slice(7, 11)}`;
    };

  } else {
    parsedTel = "";
  }
  return parsedTel;
};