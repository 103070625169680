import styled from "styled-components";
import colors from "../../../../template/styles/colors";

export const WarningText = styled.div`
  color: ${colors.yellowOfficial};
`;

export const CheckboxDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 8px 0;
  padding: 8px 0 8px 0;

  input {
    margin: 0;
    padding: 0;
  }

  label {
    margin: 0 0 0 8px;
    padding: 0;
  }
`;

export const DivLink = styled.div`
  padding: 5px;
  a {
    font-size: 16px;
    text-decoration: none;
  }
`;
