/* eslint-disable */
import React, { useState } from "react";

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = () => setValue((prevState) => !prevState);
  const setAsTrue = () => setValue(true);
  const setAsFalse = () => setValue(false);

  return { value, toggle, setAsTrue, setAsFalse };
};

export default useToggle;
