import styled from "styled-components";

import colors from "../../styles/colors";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.mediumGrey};
  padding: 33px;
`;
