import React from "react";
import { Container } from "./styles";

import options from "../../../../../nav";

import MenuOption from "../MenuOption";

const Menu = () => {
  return (
    <Container>
      {options.map((option, idx) => (
        <MenuOption key={idx} option={option} />
      ))}
    </Container>
  );
};

export default Menu;
