import styled from "styled-components";
import colors from "../../../styles/colors";
const CustomSelect = styled.select`
  appearance: none;
  min-width: 30px;
  max-width: 40px;
  border: none;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  border: 1px solid ${colors.mediumGrey};
  border-left: none;
`;

export default CustomSelect;
