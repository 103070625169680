import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { BsBell } from "react-icons/bs";
import { RiLogoutBoxLine } from "react-icons/ri";
import colors from "../../styles/colors";

export const Container = styled.header`
  background: #fff;
  position: absolute;
  top: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  box-shadow: 1px 1px 10px ${colors.darkGrey};
  padding: 0 10px;

  img {
    margin-right: 12px;
  }

  div {
    display: flex;
    align-items: center;
  }

  a {
    margin-left: 30px;
    color: ${colors.ultraDarkGrey};

    :hover {
      text-decoration: none;
      color: ${colors.blueOfficial};
    }
  }
`;

const genericIconStyle = `
  color: ${colors.ultraDarkGrey};
  font-size: 20px;
  cursor: pointer;
  :hover {
    color: ${colors.blueOfficial};
  }
`;

export const CustomFaBars = styled(FaBars)`
  ${genericIconStyle}
`;

export const CustomBsBell = styled(BsBell)`
  ${genericIconStyle}
  margin-right: 20px;
`;

export const CustomRiLogoutBoxLine = styled(RiLogoutBoxLine)`
  ${genericIconStyle}
  margin-left: 5px;
`;

export const ProfileImageFrame = styled.div`
  background: black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 20px;
`;

const genericHeaderButton = `
	border: none;
	background: none;
	color: ${colors.ultraDarkGrey};

	:hover {
		color: ${colors.blueOfficial};
		.inside {
      color: ${colors.blueOfficial};		
		}
	}
`;

export const LogoutButton = styled.button`
  ${genericHeaderButton};
  margin-left:15px;
`;

export const SystemButton = styled.button`
  ${genericHeaderButton};
`;
