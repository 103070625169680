import styled from "styled-components";

import colors from "../../styles/colors";

interface ContainerProps {
  isOpen: Boolean;
}

interface BrandProps {
  logo: string;
}
// TODO (Pedro) Criar uma transição mais suave para esse elemento
export const Container = styled.aside`
  background: ${colors.light};
  min-width: 300px;
  width: 22%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 11;
  box-shadow: 2px 0px 10px ${colors.darkGrey};
  padding-top: 70px;
  @keyframes slideInLeftPercent {
    0% {
      margin-left: -22%;
    }
    100% {
      margin-left: 0%;
    }
  }
  @keyframes slideOutLeftPercent {
    0% {
      margin-left: 0%;
    }
    100% {
      margin-left: -22%;
    }
  }
  @keyframes slideInLeftPx {
    0% {
      margin-left: -300px;
    }
    100% {
      margin-left: 0%;
    }
  }
  @keyframes slideOutLeftPx {
    0% {
      margin-left: 0%;
    }
    100% {
      margin-left: -300px;
    }
  }
  ${(props: ContainerProps) =>
    props.isOpen
      ? "animation-name: slideInLeftPx;"
      : "animation-name: slideOutLeftPx;"};
  @media (min-width: 900px) {
    position: relative;
  }
  @media (min-width: 1367px) {
    ${(props: ContainerProps) =>
      props.isOpen
        ? "animation-name: slideInLeftPercent;"
        : "animation-name: slideOutLeftPercent;"};
  }
  /* animation-duration: 0.5s; */
  animation-fill-mode: both;
`;

export const SidebarBrand = styled.div`
  width: 100%;
  height: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props: BrandProps) => props.logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
