import React, { useState, useEffect, useCallback } from "react";

import {
  CustomTable,
  CustomTableHeader,
  CustomTableBody,
  SelectButton,
  TableContainer,
} from "./styles";

import TableRow from "./components/TableRow";

interface TableDataElement {
  id: number;
  [k: string]: any;
}

interface TableProps {
  fields: string[];
  rows: TableDataElement[];
  functions?: any[];
  hasSelection: boolean;
  hasSelectionAll: boolean;
  changeSelected?: (obj: TableDataElement) => void;
  addAll?: () => void;
  removeAll?: () => void;
  changeOne?: (obj: any) => void;
  clear?: boolean;
  isMultiple?: boolean;
  resetClear?: () => void;
  iconName?: string;
}

const selectedArray = (list: TableDataElement[]) => {
  let selectedArray: any[] = [];
  list.forEach((element: any) => {
    let obj = { isSelected: false, id: null };
    obj.id = element.id;
    selectedArray.push(obj);
  });
  return selectedArray;
};

const Table = ({
  fields,
  rows,
  functions,
  hasSelection,
  hasSelectionAll,
  changeSelected,
  addAll,
  removeAll,
  changeOne,
  isMultiple,
  clear,
  resetClear,
  iconName,
}: TableProps) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedList, setSelectedList] = useState<TableDataElement[]>([]);

  useEffect(() => {
    setSelectedList(selectedArray(rows));
  }, [rows]);

  const unselectAll = useCallback(() => {
    setSelectedList((prev) =>
      prev.map((element) => {
        element.isSelected = false;
        return element;
      })
    );
    setIsAllSelected(false);
    if (removeAll) {
      removeAll();
    }
  }, [removeAll]);

  useEffect(() => {
    if (clear) {
      unselectAll();
      if (resetClear) resetClear();
    }
  }, [clear, resetClear, unselectAll]);

  const selectAll = useCallback(() => {
    setSelectedList((prev) =>
      prev.map((element) => {
        element.isSelected = true;
        return element;
      })
    );
    setIsAllSelected(true);
    if (addAll) {
      addAll();
    }
  }, [addAll]);

  const selectMultiple = (obj: any) => {
    setSelectedList((prev) =>
      prev.map((element) => {
        if (element.id === obj.id) {
          return { ...element, isSelected: !element.isSelected };
        }
        return element;
      })
    );

    if (changeSelected) changeSelected(obj);
  };

  const selectOne = (obj: any) => {
    setSelectedList((prev) =>
      prev.map((element) => {
        element.isSelected = false;
        if (element.id === obj.id) {
          element.isSelected = true;
          return element;
        }
        return element;
      })
    );
    if (changeOne) changeOne(obj);
  };

  // const rowOnChangeCallback = useCallback(
  //   (obj: TableDataElement) => {
  //     setSelectedList((prev) =>
  //       prev.map((element) => {
  //         const isEqual = element.id === obj.id;
  //         if (isMultiple) {
  //           return {
  //             ...element,
  //             isSelected: isEqual ? !element.isSelected : element.isSelected,
  //           };
  //         } else {
  //           return { ...element, isSelected: isEqual && !element.isSelected };
  //         }
  //       })
  //     );
  //     // isMultiple? !!changeSelected && changeSelected(obj) : !!changeOne && changeOne(obj);
  //     if (changeSelected) changeSelected(obj);
  //   },
  //   [isMultiple]
  // );

  return (
    <TableContainer>
      <CustomTable>
        <CustomTableHeader
          hasSelectionAll={hasSelectionAll}
          hasSelection={hasSelection}
        >
          <tr>
            {hasSelectionAll && (
              <th>
                {isAllSelected ? (
                  <SelectButton onClick={unselectAll}>x</SelectButton>
                ) : (
                  <SelectButton onClick={selectAll} />
                )}
              </th>
            )}
            {hasSelection && !hasSelectionAll && <th></th>}
            {fields.map((field) => (
              <th key={field}>{field}</th>
            ))}
          </tr>
        </CustomTableHeader>
        <CustomTableBody
          hasSelectionAll={hasSelectionAll}
          hasSelection={hasSelection}
        >
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              hasSelection={hasSelection}
              row={row}
              functions={functions}
              selectMultiple={selectMultiple}
              selectOne={selectOne}
              isMultiple={isMultiple}
              selectedElement={selectedList.find(
                (element: any) => element.id === row.id
              )}
              iconName={iconName}
            ></TableRow>
          ))}
        </CustomTableBody>
      </CustomTable>
    </TableContainer>
  );
};

export default Table;
