import React from "react";

import Modal from "../../../../template/components/Modal";
import EditarForm from "../EditarForm";

import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  row: any;
  loadRotas: () => void;
}

const EditarModal = ({ isOpen, toggle, row, loadRotas }: IProps) => {
  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={toggle}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave type="submit" form="cadastrarForm">
          <CustomAiOutlineCheck /> Salvar
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      fixed={true}
      modalTitle={`Editar nova Rota de Entrega`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <EditarForm row={row} toggle={toggle} loadRotas={loadRotas} />
    </Modal>
  );
};

export default EditarModal;
