import styled from "styled-components";
import { Table } from "reactstrap";
import colors from "../../styles/colors";

interface Iprops {
  hasSelectionAll: boolean;
  hasSelection: boolean;
}

export const TableContainer = styled.div`
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CustomTable = styled(Table)`
  border: 1px solid ${colors.darkGrey};
  border-top: none;
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;
  th {
    vertical-align: middle;
    text-align: center;
  }
`;

export const CustomTableHeader = styled.thead`
  background-color: ${colors.blueOfficial};
  color: #fff;
  tr th:first-child {
    border-top-left-radius: 8px;
    ${(props: Iprops) => (props.hasSelectionAll ? "width: 30px;" : "")};
  }
  tr th:last-child {
    border-top-right-radius: 8px;
  }
  tr th {
    vertical-align: middle;
    text-align: center;
  }
`;

export const CustomTableBody = styled.tbody`
  border: 1px solid ${colors.darkGrey};
  border-top: none;
  border-collapse: separate;
  border-radius: 8px;
  border-spacing: 0;

  th:first-child {
    ${(props: Iprops) => (props.hasSelection ? "width: 30px" : "")};
  }
`;

export const SelectButton = styled.button`
  width: 1rem;
  min-height: 1rem;
  background: #fff;
  border: 1px solid ${colors.darkGrey};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  color: ${colors.darkGrey};
  line-height: 0;
`;
