import React, { useState } from "react";

import Modal from "../../../../template/components/Modal";
import CadastrarForm from "../CadastrarForm";

import { LoadingComponent } from "pages/components/Loading/styles";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  row: any;
  loadProdutores: () => void;
}

const CadastrarModal = ({ isOpen, toggle, row, loadProdutores }: IProps) => {
  const [isLoadingCadastrar, setIsLoadingCadastrar] = useState(false);

  const handleClose = () => {
    if (isLoadingCadastrar) return;
    toggle();
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="cadastrarForm"
          disabled={isLoadingCadastrar}
        >
          {isLoadingCadastrar ? (
            <LoadingComponent height="10px" width="10px" />
          ) : (
            <CustomAiOutlineCheck />
          )}{" "}
          <span>Salvar</span>
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={`Cadastrar novo Produtor`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <CadastrarForm
        setIsLoading={setIsLoadingCadastrar}
        toggle={handleClose}
        loadProdutores={loadProdutores}
      />
    </Modal>
  );
};

export default CadastrarModal;
