export const CPFMask = {
    maxLength: 14,
    validate: (value: string, mayBeEmpty?: boolean) => {
      return mayBeEmpty
        ? value.length === 0 || value.length === 14
        : value.length === 14;
    },
    mask: "999.999.999-99",
    removeSpecialCharacters: (value: string | null) =>
      value === null ? null : value.replace(/[^\w\s]/g, "").trim(),
  };
  
  export const CNPJMask = {
    maxLength: 18,
    validate: (value: string, mayBeEmpty?: boolean) => {
      return mayBeEmpty
        ? value.length === 0 || value.length === 18
        : value.length === 18;
    },
    mask: "99.999.999/9999-99",
    removeSpecialCharacters: (value: string) =>
      value.replace(/[^\w\s]/g, "").trim(),
};  