import React from "react";
import { Container, LoadingComponent } from "./styles";

interface ILoading {
  heigthContainer?: string;
  heightLoading?: string;
  widthLoading?: string;
}

const Loading = ({
  heigthContainer,
  widthLoading,
  heightLoading,
}: ILoading) => {
  return (
    <Container height={heigthContainer}>
      <LoadingComponent height={heightLoading} width={widthLoading} />
    </Container>
  );
};

export default Loading;
