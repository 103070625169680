import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

// Fonte
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

// Estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "black",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 11,
    padding: 2,
    color: "black",
    backgroundColor: "#cccccc",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  typeTitle: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 13,
    fontStyle: "italic",
    color: "black",
    marginBottom: 15,
  },
  bodyText: {
    fontFamily: "Open Sans",
    fontWeight: 500,
    fontSize: 10,
    textAlign: "justify",
  },
  space: {
    height: 20,
  },
  bodyFooter: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    textAlign: "center",
  },
  signView: {
    display: "flex",
    flexDirection: "row",
  },
  signEmpty: {
    flex: 1,
  },
  sign: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    borderTopWidth: 1,
    borderTopColor: "black",
    minWidth: 200,
  },
  fakeTable: {
    borderWidth: 1,
    borderColor: "black",
    width: 80,
    position: "absolute",
    right: 0,
  },
  fakeTableCell: {
    fontSize: 8,
  },
  fakeTableCellMid: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: "black",
    borderBottomColor: "black",
    fontSize: 8,
  },
  fakeTableText: {
    fontSize: 10,
  },
});

type DataTableValue = {
  [k: string]: string;
};

interface IProps {
  data: DataTableValue[];
  count: number;
  start: string;
  end: string;
}

const RequerimentoPDF = ({ data, count, start, end }: IProps) => {
  return (
    <>
      <Document title="Requerimento">
        <Page size="A4" style={styles.page}>
          <View style={styles.headerTextSection}>
            <Text style={styles.headerText}>
              NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA - LEITE DO SERTÃO
            </Text>
            <Text style={styles.headerText}>
              CNPJ: 05777741000105 Insc. Estadual:
            </Text>
            <Text style={styles.headerText}>AV. SENADOR DUARTE FILHO, 450</Text>
            <Text style={styles.headerText}>
              RINCÃO - MOSSORO CEP: 59626100
            </Text>
            <Text style={styles.headerText}>
              Telefone: 3317-1599 Celular: 8839-3694
            </Text>
            <Text style={styles.headerText}>
              Email: raimundapriscilajoão2011@hotmail.com.br
            </Text>
            <View style={styles.fakeTable}>
              <View style={styles.fakeTableCell}>
                <Text style={styles.fakeTableText}>Órgão: SETHAS</Text>
              </View>
              <View style={styles.fakeTableCellMid}>
                <Text style={styles.fakeTableText}>Número:</Text>
              </View>
              <View style={styles.fakeTableCell}>
                <Text style={styles.fakeTableText}>Folha:</Text>
              </View>
            </View>
          </View>
          <View style={styles.titleTextSection}>
            <Text style={styles.typeTitle}>
              R&nbsp;E&nbsp;Q&nbsp;U&nbsp;E&nbsp;R&nbsp;I&nbsp;M&nbsp;E&nbsp;N&nbsp;T&nbsp;O
            </Text>
          </View>
          <Text style={styles.bodyText}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pelo presente
            estamos solicitando o pagamento da Fatura No 44598, do contrato
            016/2017 referente ao fornecimento de Leite BOVINO Pasteurizado
            padronizado, durante o período de 16 a 31 de Janeiro de 2021, no
            quantitativo de 68815.00 litros, perfazendo o valor de R$ 189241.25
            ( cento e oitenta e nove mil, duzentos e quarenta e um reais e vinte
            e cinco centavos), conforme disposições contidas no contrato.
          </Text>
          <View style={styles.space} />
          <Table data={[...data]}>
            <TableHeader>
              <TableCell style={styles.cell}>Produtor</TableCell>
              <TableCell style={styles.cell}>CPF</TableCell>
              <TableCell style={styles.cell}>Município</TableCell>
              <TableCell style={styles.cell}>Agência</TableCell>
              <TableCell style={styles.cell}>Conta</TableCell>
              <TableCell style={styles.cell}>Banco</TableCell>
              <TableCell style={styles.cell}>Valor (R$)</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.produtor}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.cpf}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.municipio}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.agencia}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.conta}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.banco}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.valor}
              />
            </TableBody>
          </Table>
          <View style={styles.space} />
          <Table data={[...data]}>
            <TableHeader>
              <TableCell style={styles.cell}>Contratada</TableCell>
              <TableCell style={styles.cell}>CNPJ</TableCell>
              <TableCell style={styles.cell}>Banco</TableCell>
              <TableCell style={styles.cell}>Agência</TableCell>
              <TableCell style={styles.cell}>Conta</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.contratada}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.cnpj}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.banco}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.agencia}
              />
              <DataTableCell
                style={styles.cellData}
                getContent={(r) => r.conta}
              />
            </TableBody>
          </Table>
          <View style={styles.space} />
          <Text style={styles.headerText}>
            AUTORIZAMOS O PAGAMENTO DOS PRODUTORES DIRETAMENTE NAS CONTAS
            CORRENTES DOS MESMOS, NO VALOR TOTAL DE R$123867 ( cento e vinte e
            três mil e oitocentos e sessenta e sete reais )
          </Text>
          <View style={styles.space} />
          <Text style={styles.bodyFooter}>
            MOSSORO, 10 de Fevereiro de 2021
          </Text>
          <View style={styles.space} />
          <View style={styles.space} />
          <View style={styles.signView}>
            <View style={styles.signEmpty} />
            <Text style={styles.sign}>
              NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default RequerimentoPDF;
