import React, { useState, useEffect, useCallback } from "react";
import Table from "../../template/components/Table";
import MainContainer from "../../template/components/MainContainer";
import { GoPlus } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import Spinner from "../../assets/icons/spinner.svg";
import api from "../../services/api";
import { parseJsonToTableDataList } from "../../template/utils/parser";
import { dialogBox } from "../../template/utils/dialogBox";
import Layout from "../../template/components/Layout";
import Button from "../../template/components/Button";
import SearchBar from "../../template/components/SearchBar";
import Pagination from "../../template/components/Pagination";
import { Container, SpinnerContainer } from "./styles";
import CadastrarModal from "./Components/CadastrarModal";
import DesabilitarModal from "./Components/DesabilitarModal";

const Produtores = () => {
  const [produtores, setProdutores] = useState<any[]>([]);
  const [selectedProdutor, setSelectedProdutor] = useState<any>(null);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [modalAlterar, setmodalAlterar] = useState(false);
  const itemsPerPage = 20;
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const fields = [
    "Nome/Razão Social",
    "CPF/CPNJ",
    "Município",
    "Tipo de leite",
    "EMATER",
    "Situação",
    "Ação",
  ];

  const loadProdutores = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `produtores/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&status=2&ordering=-updated_at`
      );
      const { results, count } = data;
      setProdutores(
        parseJsonToTableDataList(parseProdutores(results), [
          "nome",
          "cpf",
          "municipio",
          "tipo_leite",
          "cert_EMATER",
          "status",
        ])
      );
      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
    setIsLoading(false);
  }, [offset, searchedValue, notify]);

  useEffect(() => {
    loadProdutores();
  }, [loadProdutores]);

  function parseProdutores(produtores: any[]) {
    let tmp = [];
    for (var i = 0; i < produtores.length; i++) {
      const tipoLeite =
        produtores[i]?.tipo_leite === 1
          ? "Caprino"
          : produtores[i]?.tipo_leite === 2
          ? "Bovino"
          : "Bovino e Caprino";
      const object = {
        id: produtores[i].id,
        nome: produtores[i]?.pessoa.nome.toUpperCase(),
        cpf: produtores[i]?.documento,
        municipio: produtores[i]?.municipio,
        tipo_leite: tipoLeite,
        cert_EMATER: produtores[i]?.certificado_emater,
        status: "Ativo",
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  const toggleDesabilitar = () => {
    setmodalAlterar(!modalAlterar);
  };

  const desabilitar = (row: any) => {
    toggleDesabilitar();
    setSelectedProdutor(row);
  };

  const desabilitarProdutor = async (row: any, data: any) => {
    try {
      if (row.status === "Ativo") {
        await api.patch(`/produtores/${row.id}/`, {
          status: 3,
        });
        notify("success", "Produtor desabilitado");
        loadProdutores();
      } else {
        notify("", "Sem alterações");
      }
    } catch (err) {
      notify("error", `${err}`);
    }
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer titlePage="Lista de Produtores" iconPage={<FiUsers />}>
        <Container>
          <DesabilitarModal
            isOpen={modalAlterar}
            toggle={toggleDesabilitar}
            row={selectedProdutor}
            desabilitarProdutor={desabilitarProdutor}
            isLoading={isLoading}
          />
          {modalCadastrar && (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              row={selectedProdutor}
              loadProdutores={loadProdutores}
            />
          )}
          <div className="row">
            <Button
              color="green"
              name="Cadastrar Produtor"
              iconButtom={<GoPlus />}
              onClick={() => toggleCadastrar()}
            />
            <span className="space"></span>
            {(produtores.length > 0 || searchedValue) && (
              <div className="searchBar space">
                <SearchBar
                  placeholder="Nome ou CPF/CNPJ"
                  submitHandler={search}
                />
              </div>
            )}
          </div>
          {isLoading && (
            <SpinnerContainer>
              <img src={Spinner} alt="carregando" height={50} width={50} />
            </SpinnerContainer>
          )}
          {!isLoading && (
            <Table
              fields={fields}
              rows={produtores}
              hasSelection={false}
              hasSelectionAll={false}
              iconName="CustomBsPencil"
              functions={[
                {
                  name: "Desabilitar",
                  action: desabilitar,
                },
              ]}
            />
          )}
          <div className="containerFooterTable">
            Visualizando {produtores.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Produtores;
