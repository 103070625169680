import styled from "styled-components";
import colors from "./colors";
import {
  BsCheckAll,
  BsExclamationOctagon,
  BsExclamationTriangle,
} from "react-icons/bs";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import arrow from '../assets/icons/arrow.png';

export const CustomToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    background: ${colors.blueOfficial};
    border-radius: 8px;
  }
  .Toastify__toast--error {
    background: ${colors.redError};
    border-radius: 8px;
  }
  .Toastify__toast--warning {
    background: ${colors.yellowOfficial};
    border-radius: 8px;
  }
  .Toastify__toast--success {
    background: ${colors.greenOfficial};
    border-radius: 8px;
  }
`;

const genericIcon = `
    color: #fff;
    font-size: 30px;
    margin-right: 8px;
`;

export const InfoIcon = styled(AiOutlineExclamationCircle)`
  ${genericIcon}
`;

export const SuccessIcon = styled(BsCheckAll)`
  ${genericIcon}
`;

export const WarningIcon = styled(BsExclamationTriangle)`
  ${genericIcon}
`;

export const ErrorIcon = styled(BsExclamationOctagon)`
  ${genericIcon}
`;

export const DialogBoxContent = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: 16px;
    color: #fff;
  }
`;

export const CustomSelect = styled.select`
  height: 39px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
  color: ${colors.ultraDarkGrey};
  border: 1px solid #C8CED3;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 35px 8px 8px;
  background: url(${arrow}) 95% / 10px no-repeat;
  background-color: ${colors.light};

`;

export const CustomInput = styled.div`
  .CustomInput {
    border: 1px solid #C8CED3;
    box-sizing: border-box;
    border-radius: 5px;
    height: 38px;
    width: 100%;
    padding: 8px 12px 8px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    ::placeholder {
     color: ${colors.darkGrey}
    }
  }
`;
