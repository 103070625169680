import React from "react";

import { CustomButton, CustomIcon, Container } from "./styles";

interface IProps {
  name: string;
  iconButtom: React.ReactNode;
  color: string;
  onClick?: () => void;
}

const Button = ({ name, iconButtom, color, onClick }: IProps) => {
  return (
    <Container onClick={onClick}>
      <CustomButton className={color}>
        <CustomIcon>{iconButtom}</CustomIcon>
        <span className="text">{name}</span>
      </CustomButton>
    </Container>
  );
};

export default Button;
