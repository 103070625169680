import React from "react";

import { Container, FooterItem, FooterIcons } from "./styles";

import djangoIcon from "../../assets/icons/django_icon.svg";
import reactIcon from "../../assets/icons/react_icon.svg";

const Footer = () => {
  return (
    <Container>
      <FooterItem>
        <p>Administração</p>
        <b>SETHAS</b>
      </FooterItem>
      <FooterItem>
        <p>Desenvolvimento</p>
        <b>NUDTI</b>
      </FooterItem>
      {/* <FooterItem>
        <p>Última importação do CadÚnico</p>
        <b>25/01/2020 12:00h</b>
      </FooterItem> */}
      {/* <FooterItem>
        <p>Última atualização do Sistema</p>
        <b>22/02/2020 20:00</b>
      </FooterItem> */}
      <FooterItem>
        <FooterIcons>
          <img src={reactIcon} alt="React Icon" />
          <img src={djangoIcon} alt="Djnago Icon" />
        </FooterIcons>
      </FooterItem>
    </Container>
  );
};

export default Footer;
