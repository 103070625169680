import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import FileSaver from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { MdContentPaste } from "react-icons/md";
import { GoMegaphone } from "react-icons/go";
import { FaClipboard } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { AiOutlineFile } from "react-icons/ai";
import Table from "../../template/components/Table";
import MainContainer from "../../template/components/MainContainer";
import api from "../../services/api";
import { parseJsonToTableDataList } from "../../template/utils/parser";
import { dialogBox } from "../../template/utils/dialogBox";
import Layout from "../../template/components/Layout";
import { CustomSelect } from "../../template/styles/styles";
import { getToken, parseJwt } from "../../services/auth";
import Button from "../../template/components/Button";
import { CustomLabel, Content } from "./styles";
import SearchBar from "../../template/components/SearchBar";
import Pagination from "../../template/components/Pagination";
import CadastrarModal from "./components/CadastrarModal";
import {
  PeriodoInterface,
  BancoInterface,
  NotaFiscalInterface,
  PontoRelatorio,
  EntregaRelatorio,
} from "./utils/types";
import { ModalEntregas } from "./components/ModalEntregas";
import RelatorioPDF from "./components/RelatorioPDF";
import ReciboPDF from "./components/ReciboPDF";
import RequerimentoPDF from "./components/RequerimentoPDF";

const PrestarConta = () => {
  const [latPontos, setLatPontos] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [periodos, setPeriodos] = useState<PeriodoInterface[] | any[]>([]);
  const [periodoSelect, setPeriodoSelect] = useState<PeriodoInterface | any>(
    {}
  );
  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number>(Number(new Date().getFullYear()));
  const [quinzenaId, setQuinzenaId] = useState<number>(1); //MUDAR
  const fields = [
    "Ponto",
    "Municipio",
    "Qtd bovino",
    "Qtd caprino",
    "Situação",
    "Ação",
  ];
  const { register } = useForm();
  const [modalNota, setModalNota] = useState(false);
  const [entregas, setEntregas] = useState<any>([]);
  const [laticinioId, setLaticinioId] = useState<number>();
  const [bancos, setBancos] = useState<BancoInterface[]>([]);
  const [notaFiscal, setNotaFiscal] = useState<NotaFiscalInterface | any>({});
  const [gestorLaticinio, setGestorLaticinio] = useState<any>();
  const [ponto, setPonto] = useState<string>("");
  const [totalValorBovino, setTotalValorBovino] = useState<Number>();
  const [totalValorCaprino, setTotalValorCaprino] = useState<Number>();
  const [totalEntregueBovino, setTotalEntregueBovino] = useState<Number>();
  const [totalEntregueCaprino, setTotalEntregueCaprino] = useState<Number>();
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [arrayReport, setArrayReport] = useState<any[]>([]);
  const [createDoc, setCreateDoc] = useState<any>(false);
  const [modalDetalhar, setModalDetalhar] = useState<boolean>(false);
  const [periodoLabel, setPeriodoLabel] = useState<String>("");
  const perPage = 10;

  //Exibe os toasts de erro ou sucesso
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  //Inicializa as contantes com os anos dos quais devem ser buscado periodos
  useEffect(() => {
    const handleYears = () => {
      const initialYear = 2015;
      const finalYear = new Date().getFullYear();
      let oneMoreYear = initialYear + 1;
      let allYears = [initialYear];
      //Gera os anos entre o inicial e o final
      while (oneMoreYear !== finalYear) {
        allYears.push(oneMoreYear);
        oneMoreYear = oneMoreYear + 1;
      }
      allYears.push(finalYear);
      setYears(allYears);
    };

    handleYears();
  }, []);

  //Recebe os periodos
  useEffect(() => {
    async function loadPeriodos() {
      let periodos = await api.get(`periodos/?ano=${year}`);

      if (periodos.data.length) {
        setPeriodos(periodos.data);
      } else {
        setPeriodoSelect(0);
        setPeriodos([]);
        notify("error", "Não registros de periodos no ano selecionado");
      }
      handleQuinzenas(quinzenaId);
    }
    loadPeriodos();
  }, [year, quinzenaId]);

  //Recebe o id do laticínio
  useEffect(() => {
    const loadLaticinio = async () => {
      const token: any = getToken();
      const user = parseJwt(token);
      const gestor_laticinio: any = await api.get(
        `/gestores_laticinio/?usuario=${user.user_id}`
      );
      setLaticinioId(gestor_laticinio.data[0].laticinio.id);
      setGestorLaticinio(gestor_laticinio.data[0]);
    };
    loadLaticinio();
  }, []);

  useEffect(() => {
    const loadPontos = async () => {
      if (periodoSelect.data_inicio) {
        const { data } = await api.get(
          `/pontos/?laticinio=${laticinioId}&limit=${perPage}&offset=${offset}&data=${periodoSelect.data_inicio}`
        );
        const entries = await api.get(
          `/entregas/?periodo=${quinzenaId}&gestor_laticinio=${gestorLaticinio.id}`
        );

        if (data.results.length && periodos.length) {
          setTotal(data.count);
          setLatPontos(
            parseJsonToTableDataList(parseLatPontos(data.results, entries), [
              "ponto",
              "municipio",
              "cota_leite_bovino",
              "cota_leite_caprino",
              "situacao",
            ])
          );
        } else {
          setLatPontos([]);
          setCreateDoc(false);
          notify("error", "Não ha registros de pontos no periodo selecionado");
        }
      }
    };

    if (laticinioId !== null && laticinioId !== undefined) {
      loadPontos();
    }
  }, [periodoSelect, year]);

  //Recebe todas as entregas realizadas em um determinado periodo, entregas de todos os pontos
  useEffect(() => {
    if (latPontos.length) {
      const getEntregas = async () => {
        const entregas = await api.get(
          `/entregas/?gestor_laticinio=${gestorLaticinio.id}&periodo=${periodoSelect.id}`
        );
        setDeliveries(entregas.data);
      };

      getEntregas();
    }
  }, [latPontos]);

  //Gera o array que vai ser utilizado pelo relatorio de entregas
  useEffect(() => {
    if (deliveries.length) {
      //Gerar array do relatorio
      const generateDeliveriesReport = (entregas: EntregaRelatorio[]) => {
        let arrayTmp: PontoRelatorio[] = [];
        let totalBovino = 0;
        let totalCaprino = 0;
        let entregueBovino = 0;
        let entregueCaprino = 0;

        latPontos.map((item) => {
          let obj = {
            id: item.id,
            ponto: item.ponto,
            cota_leite_bovino: item.cota_leite_bovino,
            cota_leite_caprino: item.cota_leite_caprino,
            bovino_entregue: 0,
            caprino_entregue: 0,
            entregas: [] as EntregaRelatorio[],
            valor_bovino: 0,
            valor_caprino: 0,
          };

          entregas.map((entrega) => {
            if (entrega.ponto === obj.ponto) {
              obj.entregas.push(entrega);
              obj.bovino_entregue =
                obj.bovino_entregue + entrega.quantidade_leite_bovino;
              obj.caprino_entregue =
                obj.caprino_entregue + entrega.quantidade_leite_caprino;

              obj.valor_bovino =
                obj.valor_bovino +
                entrega.quantidade_leite_bovino * entrega.preco_leite_bovino;
              obj.valor_caprino =
                obj.valor_caprino +
                entrega.quantidade_leite_caprino * entrega.preco_leite_caprino;

              totalBovino =
                totalBovino +
                entrega.quantidade_leite_bovino * entrega.preco_leite_bovino;
              totalCaprino =
                totalCaprino +
                entrega.quantidade_leite_caprino * entrega.preco_leite_caprino;

              entregueBovino = entregueBovino + entrega.quantidade_leite_bovino;
              entregueCaprino =
                entregueCaprino + entrega.quantidade_leite_caprino;
            }
          });

          arrayTmp.push(obj);

          setTotalValorBovino(totalBovino);
          setTotalValorCaprino(totalCaprino);
          setTotalEntregueBovino(entregueBovino);
          setTotalEntregueCaprino(entregueCaprino);
        });

        setArrayReport(arrayTmp);

        //mostra ou esconde os botoes de cadastro conforme se tiver entregas ou n
        if (arrayTmp.length > 0) {
          setCreateDoc(true);
        } else {
          setCreateDoc(false);
        }
      };

      generateDeliveriesReport(deliveries);
    } else {
      setCreateDoc(false);
    }
  }, [deliveries]);

  //Recebe os bancos de um laticínio
  useEffect(() => {
    const loadBancos = async () => {
      const { data } = await api.get(`bancos/?laticinio=${laticinioId}`);

      if (data !== null && data !== undefined) {
        setBancos(data);
      }
    };

    if (laticinioId !== null && laticinioId !== undefined) {
      loadBancos();
    }
  }, [laticinioId]);

  //Recebe as entregas de um ponto especifico
  const detalhar = async (row: any) => {
    setPonto(row.ponto);
    const dataEntregas = await api.get(
      `/entregas/?ponto=${row.id}&periodo=${periodoSelect.id}`
    );
    if (dataEntregas.data.length) {
      setPonto(dataEntregas.data[0].ponto);
    }
    setEntregas(
      parseJsonToTableDataList(parseEntregas(dataEntregas.data), [
        "data",
        "cota_leite_bovino",
        "cota_leite_caprino",
      ])
    );

    toggleDetalhar();
  };

  //Converte o formato de ponto para um que seja aceito na tabela
  function parseLatPontos(latPontos: any[], entries: any) {
    let tmp = [];

    for (var i = 0; i < latPontos.length; i++) {
      const object = {
        id: latPontos[i].id,
        ponto: latPontos[i].nome,
        municipio: latPontos[i].endereco.municipio.nome,
        cota_leite_bovino: latPontos[i].cota_leite_bovino,
        cota_leite_caprino: latPontos[i].cota_leite_caprino,
        situacao: "PENDENTE",
      };
      tmp.push(object);
    }

    tmp.map((ponto) => {
      for (var i = 0; i < entries.data.length; i++) {
        if (ponto.ponto === entries.data[i].ponto) {
          ponto.situacao = "";
        }
      }
    });

    return tmp;
  }

  //Converte o formato de entrega para um que seja aceito na tabela
  function parseEntregas(entregas: any[]) {
    let tmp = [];
    for (var i = 0; i < entregas.length; i++) {
      const object = {
        data: entregas[i].data,
        cota_leite_bovino: entregas[i].quantidade_leite_bovino,
        cota_leite_caprino: entregas[i].quantidade_leite_caprino,
      };
      tmp.push(object);
    }
    return tmp;
  }

  //Converte a data inicial do periodo para a data padrão do Brasil
  const translateDate = (data: string) => {
    let splitedDate = data.split("-");

    if (splitedDate[1] === "01") {
      return `${splitedDate[2]} de Janeiro`;
    } else if (splitedDate[1] === "02") {
      return `${splitedDate[2]} de Fevereiro`;
    } else if (splitedDate[1] === "03") {
      return `${splitedDate[2]} de Março`;
    } else if (splitedDate[1] === "04") {
      return `${splitedDate[2]} de Abril`;
    } else if (splitedDate[1] === "05") {
      return `${splitedDate[2]} de Maio`;
    } else if (splitedDate[1] === "06") {
      return `${splitedDate[2]} de Junho`;
    } else if (splitedDate[1] === "07") {
      return `${splitedDate[2]} de Julho`;
    } else if (splitedDate[1] === "08") {
      return `${splitedDate[2]} de Agosto`;
    } else if (splitedDate[1] === "09") {
      return `${splitedDate[2]} de Setembro`;
    } else if (splitedDate[1] === "10") {
      return `${splitedDate[2]} de Outubro`;
    } else if (splitedDate[1] === "11") {
      return `${splitedDate[2]} de Novembro`;
    }

    return `${splitedDate[2]} de Dezembro`;
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * perPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Passa para a proxima página
  const next = useCallback(() => {
    let newOffset = offset + 10;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - 10;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  //Vai direto para a última página
  const last = useCallback(() => {
    setOffset(
      (total % perPage === 0
        ? Math.floor(total / perPage)
        : Math.floor(total / perPage) + 1) *
        10 -
        10
    );
    setPage(
      total % perPage === 0
        ? Math.floor(total / perPage)
        : Math.floor(total / perPage) + 1
    );
  }, [total]);

  //Controla a exibição do modal de nota fiscal
  function toggleNotaFiscal() {
    setModalNota(!modalNota);
  }

  //Controla a exibição do modal detalhar
  function toggleDetalhar() {
    setModalDetalhar(!modalDetalhar);
  }

  //Gera o relatorio de entregas
  async function createPDF() {
    const blob = await pdf(
      <RelatorioPDF
        data={arrayReport}
        count={1}
        start={"1"}
        end={"1"}
        period={periodoSelect}
        gestorLaticinioId={gestorLaticinio.id}
        totalValorBovino={totalValorBovino}
        totalValorCaprino={totalValorCaprino}
        totalEntregueBovino={totalEntregueBovino}
        totalEntregueCaprino={totalEntregueCaprino}
      />
    ).toBlob();

    FileSaver.saveAs(blob, "relatorio.pdf");
  }

  //Gera o requerimento
  async function createRequirement() {
    const mockedData = [
      {
        produtor: "Produtor",
        cpf: "000.000.000-00",
        municipio: "município",
        agencia: "agência",
        conta: "conta",
        banco: "banco",
        valor: "valor",
        contratada: "contratada",
        cnpj: "cnpj",
      },
    ];

    const blob = await pdf(
      <RequerimentoPDF data={mockedData} count={1} start={"1"} end={"1"} />
    ).toBlob();

    FileSaver.saveAs(blob, "requerimento.pdf");
  }

  //Gera o recibo
  async function createReceipt() {
    const mockedDataRecept = [
      {
        expecificacao: "Produtor",
        valor: "000.000.000-00",
      },
    ];

    const blob = await pdf(
      <ReciboPDF data={mockedDataRecept} count={1} start={"1"} end={"1"} />
    ).toBlob();

    FileSaver.saveAs(blob, "Recibo.pdf");
  }

  // Verificar a utilidade #############################################################################

  const handleQuinzenas = (quinzenaId: number) => {
    async function loadNotaFiscal(periodoId: number) {
      for (let p of periodos) {
        if (p.id === periodoId) {
          let nota = await api.get(
            `notas_fiscais/nota_principal/?gestor_laticinio=${gestorLaticinio.id}&periodo=${periodoId}`
          );
          let entryes = await api.get(
            `entregas/?periodo=${periodoId}&gestor_laticinio=${gestorLaticinio.id}`
          );
          if (nota.data !== undefined) {
            setNotaFiscal(nota.data);
          }
          //setNFList(nota.data);
          setPeriodoSelect(p);
          break;
        }
      }
    }

    loadNotaFiscal(quinzenaId);
  };

  return (
    <Layout>
      <MainContainer titlePage="Prestar Contas" iconPage={<MdContentPaste />}>
        <Content>
          <div className="filter-select">
            <div className="select-box">
              <div className="select-ano" style={{ marginRight: "20px" }}>
                <CustomLabel style={{ textAlign: "center" }}>
                  Selecionar Ano
                </CustomLabel>
                <CustomSelect
                  name="Ano"
                  onChange={(e) => {
                    setYear(Number(e.target.value));
                  }}
                >
                  {years.map((item) =>
                    item === year ? (
                      <option key={item} value={item} defaultValue="" selected>
                        {item}
                      </option>
                    ) : (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </CustomSelect>
              </div>
              <div className="select-quinzena">
                <CustomLabel style={{ textAlign: "center" }}>
                  Selecionar Período
                </CustomLabel>
                <CustomSelect
                  name="Quinzena"
                  ref={register}
                  onChange={(e) => {
                    setPeriodoLabel(e.target.selectedOptions[0].className);
                    setQuinzenaId(parseInt(e.target.value));
                  }}
                >
                  {periodos.length > 0 ? (
                    (periodos as Array<PeriodoInterface | any>).map(
                      (periodo) => (
                        <option
                          key={periodo.id}
                          value={periodo.id}
                          className={translateDate(periodo.data_inicio)}
                        >
                          {translateDate(periodo.data_inicio)}
                        </option>
                      )
                    )
                  ) : (
                    <option key={0} value={0}>
                      Não há periodos disponiveis para esse ano
                    </option>
                  )}
                </CustomSelect>
              </div>
            </div>
            <div className="filter-search">
              <SearchBar
                submitHandler={() => {
                  console.log(`pesquisando`);
                }}
              />
            </div>
          </div>
          <div id="entries-table">
            <Table
              fields={fields}
              rows={latPontos}
              hasSelection={false}
              hasSelectionAll={false}
              functions={[
                {
                  name: "     Entregas",
                  action: detalhar,
                },
              ]}
            />
          </div>

          <div className="pagination">
            <CadastrarModal
              isOpen={modalNota}
              toggle={toggleNotaFiscal}
              className="teste"
              quinzena={periodoSelect.data_inicio}
              year={year}
              bancos={bancos}
              periodo={periodoSelect}
              periodoId={quinzenaId}
              gestorLaticinio={gestorLaticinio}
              notaFiscal={notaFiscal}
              periodoLabel={periodoLabel}
              //notas={nfList}
            />
            <ModalEntregas
              isOpen={modalDetalhar}
              //isOpen={false}
              toggle={toggleDetalhar}
              className="modal-lg"
              quinzena={periodoSelect.data_fim}
              entregas={entregas}
              year={year}
              ponto={ponto}
            />
            <Pagination
              itemCount={total}
              itemsPerPage={perPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
          <div className="table-options" hidden={!createDoc}>
            <>
              <div className="separator">
                <Button
                  color="blue"
                  name="Incluir Nota Fiscal"
                  iconButtom={<AiOutlineFile />}
                  onClick={toggleNotaFiscal}
                />
              </div>
              <Button
                color="blue"
                name="Lista de Entregas"
                iconButtom={<FaClipboard />}
                onClick={createPDF}
              />
              <div className="separator-middle">
                <Button
                  color="blue"
                  name="Requerimento"
                  iconButtom={<GoMegaphone />}
                  onClick={createRequirement}
                />
              </div>
              <Button
                color="blue"
                name="Recibo"
                iconButtom={<MdReceipt />}
                onClick={createReceipt}
              />
            </>
          </div>
        </Content>
      </MainContainer>
    </Layout>
  );
};

export default PrestarConta;
