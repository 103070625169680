import React, { useState } from "react";
import Loading from "pages/components/Loading";
import Modal from "../../../../template/components/Modal";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
  ButtonContentContainer,
} from "./styles";
import api from "services/api";
import { dialogBox } from "template/utils/dialogBox";
import { parseDate2 } from "template/utils/parser";
import { LoadingComponent } from "../../../components/Loading/styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadGerenciarFuncionario: () => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

const notify = (type: string, message: string) => dialogBox(type, message);

const DesabilitarModal = ({
  row,
  isOpen,
  toggle,
  loadGerenciarFuncionario,
  isLoading,
}: IProps) => {
  const [isLoadingDesabilitar, setIsLoadingDesabilitar] = useState(false);

  const handleDesabilitarFuncionario = () => {
    setIsLoadingDesabilitar(true);
    const atualDate = new Date();
    const updatedDate = {
      data_expiracao: parseDate2(
        new Date(
          atualDate.getFullYear(),
          atualDate.getMonth(),
          atualDate.getDate() - 1
        )
      ),
      ativo: false,
    };

    api
      .patch(`/funcionarios-laticinios/${row.id}/`, updatedDate)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Funcionário desabilitado com sucesso!`);
        // Atualiza a tabela
        loadGerenciarFuncionario();
        setIsLoadingDesabilitar(false);
        // Fecha o modal somente após a conclusão da requisição
        toggle();
      })
      .catch(function (error) {
        // Handle error here if the request fails
        console.error("Erro na requisição: ", error);
        setIsLoadingDesabilitar(false);
      });
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose} disabled={isLoadingDesabilitar}>
          <CustomAiOutlineClose /> Não
        </ButtonClose>
        <ButtonSave
          type="submit"
          onClick={handleDesabilitarFuncionario}
          disabled={isLoadingDesabilitar || isLoading}
        >
          <ButtonContentContainer>
            {isLoadingDesabilitar ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Sim</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  const handleClose = () => {
    if (!isLoadingDesabilitar) {
      toggle();
    }
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={`Desabilitar funcionário`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      {isLoading ? (
        <Loading
          heigthContainer="30px"
          heightLoading="20px"
          widthLoading="20px"
        />
      ) : (
        <h6>Você deseja desabilitar {row.pessoa.nome}?</h6>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default DesabilitarModal;
