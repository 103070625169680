import React, { useState, useEffect, useCallback } from "react";
import MainContainer from "../../template/components/MainContainer";
import { GoPlus } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import api from "../../services/api";
import { dialogBox } from "../../template/utils/dialogBox";
import Layout from "../../template/components/Layout";
import Button from "../../template/components/Button";
import SearchBar from "../../template/components/SearchBar";
import Pagination from "../../template/components/Pagination";
import {
  Container,
  CustomReactSelect,
  CustomIconDesabilitar,
  CustomIconHabilitar,
} from "./styles";
import CadastrarModal from "./Components/CadastrarModal";
import EditarModal from "./Components/EditarModal";
import DesabilitarModal from "./Components/DesabilitarModal";
import HabilitarModal from "./Components/HabilitarModal";
import Table from "../../template/components/Table";
import { parseJsonToTableDataList } from "../../template/utils/parser";

const RotaEntrega = () => {
  const [rotas, setRotas] = useState<any[]>([]);
  const [modalCadastrar, setModalCadastrar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalDesabilitar, setModalDesabilitar] = useState(false);
  const [modalHabilitar, setModalHabilitar] = useState(false);
  const [editarRota, setEditarRota] = useState<any>(Object);
  const itemsPerPage = 20;
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("true");
  const [isLoadingDetalhar, setIsLoadingDetalhar] = useState(false);

  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const fields = ["Nome", "Descrição/Obs", "Dias de Atuação", "Ações"];

  const diasAtuacao = {
    1: "Seg",
    2: "Ter",
    3: "Qua",
    4: "Qui",
    5: "Sex",
  };

  function parseDiasAtuacao(dias: any[], parseDiasAtuacao: any) {
    const novoArray = dias.map((valor) => parseDiasAtuacao[valor] || valor);
    return novoArray;
  }

  const loadRotas = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/rotas-entrega/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}&ativo=${status}&ordering=-updated_at`
      );
      const { results, count } = data;
      setRotas(
        parseJsonToTableDataList(parseRotas(results), [
          "id",
          "nome_rota",
          "descricao",
          "dias_atuacao",
        ])
      );
      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    }
  }, [offset, searchedValue, status, notify]);

  useEffect(() => {
    loadRotas();
  }, [loadRotas]);

  function parseRotas(rotas: any[]) {
    let tmp = [];

    for (var i = 0; i < rotas.length; i++) {
      const object = {
        id: rotas[i].id,
        nome_rota: rotas[i].nome_rota,
        descricao: rotas[i].descricao,
        dias_atuacao: parseDiasAtuacao(rotas[i].dias_atuacao, diasAtuacao).join(
          ", "
        ),
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  const toggleCadastrar = () => {
    setModalCadastrar(!modalCadastrar);
  };

  const toggleDesabilitar = () => {
    setModalDesabilitar(!modalDesabilitar);
  };
  const toggleHabilitar = () => {
    setModalHabilitar(!modalHabilitar);
  };

  const toggleEditar = () => {
    setModalEditar(!modalEditar);
  };

  useEffect(() => {
    loadRotas();
  }, [offset, searchedValue, status]);

  const editar = async (row: any) => {
    const { data } = await api.get(`/rotas-entrega/${row.id}/`);
    setEditarRota(data);
    toggleEditar();
  };

  const detalharDesabilitar = async (row: any) => {
    setIsLoadingDetalhar(true);
    toggleDesabilitar();
    const { data } = await api.get(`/rotas-entrega/${row.id}/`);
    setEditarRota(data);
    setIsLoadingDetalhar(false);
  };
  const detalharHabilitar = async (row: any) => {
    setIsLoadingDetalhar(true);
    toggleHabilitar();
    const { data } = await api.get(`/rotas-entrega/${row.id}/`);
    setEditarRota(data);
    setIsLoadingDetalhar(false);
  };

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);
  const handleStatus = (selectedOption: any, actionMeta: any) => {
    if (actionMeta.action === "select-option" && selectedOption) {
      const value = selectedOption.value;
      setStatus(value);
    }
  };

  return (
    <Layout>
      <MainContainer titlePage="Rotas de Entrega" iconPage={<FiUsers />}>
        <Container>
          {modalEditar && (
            <EditarModal
              isOpen={modalEditar}
              toggle={toggleEditar}
              row={editarRota}
              loadRotas={loadRotas}
            />
          )}

          {modalCadastrar && (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              row={editarRota}
              loadRotas={loadRotas}
            />
          )}
          {modalDesabilitar && (
            <DesabilitarModal
              isOpen={modalDesabilitar}
              toggle={toggleDesabilitar}
              row={editarRota}
              loadRotas={loadRotas}
              setIsLoading={setIsLoadingDetalhar}
              isLoading={isLoadingDetalhar}
            />
          )}
          {modalHabilitar && (
            <HabilitarModal
              isOpen={modalHabilitar}
              toggle={toggleHabilitar}
              row={editarRota}
              loadRotas={loadRotas}
              setIsLoading={setIsLoadingDetalhar}
              isLoading={isLoadingDetalhar}
            />
          )}

          <div className="row">
            <Button
              color="green"
              name="Cadastrar Rota"
              iconButtom={<GoPlus />}
              onClick={() => toggleCadastrar()}
            />
            <span className="space"></span>
            {(rotas.length >= 0 || searchedValue) && (
              <>
                <div>
                  <CustomReactSelect
                    name="dias_atuacao"
                    className="select space"
                    defaultValue={{ value: "true", label: "Ativas" }}
                    options={[
                      { value: "true", label: "Ativas" },
                      { value: "false", label: "Desativadas" },
                    ]}
                    onChange={handleStatus}
                  />
                </div>

                <div className="searchBar space">
                  <SearchBar
                    placeholder="Nome da rota"
                    submitHandler={search}
                  />
                </div>
              </>
            )}
          </div>

          <Table
            fields={fields}
            rows={rotas}
            hasSelection={false}
            hasSelectionAll={false}
            iconName="CustomBsPencil"
            functions={[
              { name: "Editar", action: editar },
              status === "true"
                ? {
                    name: "Desativar",
                    action: detalharDesabilitar,
                    iconBtn: <CustomIconDesabilitar />,
                  }
                : {
                    name: "Ativar",
                    action: detalharHabilitar,
                    iconBtn: <CustomIconHabilitar />,
                  },
            ]}
          />

          <div className="containerFooterTable">
            Visualizando {rotas.length} de um total de {total} registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default RotaEntrega;
