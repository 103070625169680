import styled from "styled-components";
import colors from "../../../template/styles/colors";
export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    justify-content: flext-start;
    align-items: center;
  }

  .space {
    margin-left: 20px;
  }

  .space-row {
    margin-top: 18px;
  }

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  .searchBar {
    margin-right: 9px;
  }

  .containerFooterTable {
    display: flex;
    justify-content: space-between;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  .error-message {
    color: ${colors.redError};
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
`;







