import React, { useCallback, useState } from "react";

import {
  Container,
  SearchForm,
  SearchButton,
  ResetButton,
  CustomGoSearch,
  CustomReload,
} from "./styles";
interface IProps {
  submitHandler: (input: string) => void;
  placeholder?: string;
}
const SearchBar = ({ submitHandler, placeholder = "Pesquisar" }: IProps) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [searchValue]
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      submitHandler(searchValue);
    },
    [submitHandler, searchValue]
  );

  const handleReset = useCallback(() => {
    submitHandler("");
    },
    [submitHandler]
  );

  return (
    <Container>
      <SearchForm onSubmit={handleSubmit}>
        <input
          onChange={handleChange}
          type="search"
          name="search"
          placeholder={placeholder}
        />
        <SearchButton type="submit" >
          <CustomGoSearch />
        </SearchButton>
      </SearchForm>

      <SearchForm onSubmit={handleReset}>
        <ResetButton type="submit" >
          <CustomReload />
        </ResetButton>
      </SearchForm>
    </Container>
  );
};

export default SearchBar;
