import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormContainer, CustomReactSelect, RemoveButton } from "./styles";
import { CustomInput } from "../../../../template/styles/styles";
import { Container } from "./../../styles";
import { dialogBox } from "../../../../template/utils/dialogBox";
import api from "../../../../services/api";
import { TiMinus } from "react-icons/ti";

interface IProps {
  row: any;
  toggle: () => void;
  loadRotas: () => void;
}
type RotaEntrega = {
  nome_rota: string;
  descricao: string;
  dias_atuacao: any[];
  horario_inicio: string;
  horario_fim: string;
  percurso: any[];
};

type Ponto = {
  nome: string;
  id: number;
  municipio: string;
  endereco: string;
};

const CadastrarForm: React.FC<IProps> = (props) => {
  const { handleSubmit, register, errors, control } = useForm<RotaEntrega>({
    mode: "onSubmit",
  });

  const [diasSemanaOptions, setDiasSemanaOptions] = useState<any[]>([]);
  const [diasSemanaSelecteds, setdiasSemanaSelecteds] = useState<string[]>([]);
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const [pontosOptions, setPontosOptions] = useState<any>([]);
  const [pontoSelected, setPontoSelected] = useState<any>();

  const { fields, append, remove } = useFieldArray<Ponto>({
    control,
    name: "percurso",
  });

  useEffect(() => {
    const loadPontos = async () => {
      const { data } = await api.get("pontos/");
      const pontosData = data.results;
      const options: any = [];
      pontosData?.forEach((ponto: any) => {
        options.push({ value: ponto.id, label: ponto.nome });
      });
      setPontosOptions(options);
    };
    loadPontos();
  }, []);

  const loadPonto = async (id: number) => {
    try {
      const { data } = await api.get(`pontos/${id}`);
      setPontoSelected(data);
    } catch (error) {
      console.error("Erro ao carregar ponto", error);
    }
  };

  const handleSelectPonto = (selectedPonto: any) => {
    loadPonto(selectedPonto.value);
  };

  useEffect(() => {
    if (pontoSelected) {
      append({
        id: pontoSelected.id,
        nome: pontoSelected.nome,
        municipio: pontoSelected?.endereco?.municipio?.nome,
        endereco: `${pontoSelected?.endereco?.rua}, ${pontoSelected.endereco.numero}`,
      });
    }
  }, [pontoSelected, append]);

  useEffect(() => {
    const diasSemana: any = [
      { label: "Segunda-feira", value: 1 },
      { label: "Terça-feira", value: 2 },
      { label: "Quarta-feira", value: 3 },
      { label: "Quinta-feira", value: 4 },
      { label: "Sexta-feira", value: 5 },
    ];

    setDiasSemanaOptions(diasSemana);
  }, []);

  const handleSelectChange = (selectedOptions: any) => {
    const selectedValues = selectedOptions.map((option: any) => option.value);
    setdiasSemanaSelecteds(selectedValues);
  };

  const onSubmit = async (data: any) => {
    if (data.percurso === undefined) {
      data.percurso = [];
    }
    const selectedPontos = fields.map((ponto: any) => ponto.id);

    const rota: RotaEntrega = {
      nome_rota: data.nome_rota,
      descricao: data.descricao,
      dias_atuacao: diasSemanaSelecteds,
      horario_inicio: data.horario_inicio,
      horario_fim: data.horario_fim,
      percurso: selectedPontos,
    };
    api
      .post(`/rotas-entrega/`, rota)
      .then(async (response) => {
        notify("success", `Rota cadastrada com sucesso!`);
        // Atualiza os cards
        props.loadRotas();
        //Fecha o modal
        props.toggle();
      })
      .catch(function (error) {
        notify("error", `Ocorreu um erro no cadastro, verifique o formulário`);
      });
  };

  return (
    <Container>
      <FormContainer id="cadastrarForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-sm">
            <h6>Dados da Rota</h6>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="nome">Nome</label>
              <input
                className={
                  errors.nome_rota ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="Nome da rota"
                name="nome_rota"
                ref={register({
                  required: true,
                  maxLength: 60,
                })}
              />
              {errors.nome_rota?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.nome_rota?.type === "maxLength" && (
                <span className="error-message">
                  Certifique-se de que esse campo não tenha mais que 60
                  caracteres
                </span>
              )}
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="descricao">Descrição/Observação (opcional)</label>
              <textarea
                rows={5}
                name="descricao"
                style={{ height: "auto", resize: "none" }}
                className={
                  errors.descricao ? "CustomInput error-input" : "CustomInput"
                }
                placeholder="Descrição ou observação"
                ref={register({
                  required: false,
                })}
              />
            </CustomInput>
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <label htmlFor="dias">Dias de Atuação</label>

            <CustomReactSelect
              name="dias_atuacao"
              className="select"
              placeholder="Dias da Semana"
              options={diasSemanaOptions}
              onChange={handleSelectChange}
              noOptionsMessage={() => "Nenhum dia encontrado"}
              isMulti
            />
          </div>
        </div>
        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="horario_inicio">Horario de Inicio</label>
              <input
                type="text"
                className={
                  errors.horario_inicio
                    ? "CustomInput error-input"
                    : "CustomInput"
                }
                name="horario_inicio"
                placeholder="11:30"
                ref={register({
                  required: true,
                  maxLength: 5,
                })}
                onChange={(e: any) => {
                  e.currentTarget.value = e.currentTarget.value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{0,2})/, "$1:$2")
                    .substring(0, 5);
                }}
              />
              {errors.horario_inicio?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.horario_inicio?.type === "maxLength" && (
                <span className="error-message">Formato de hora inválido.</span>
              )}
            </CustomInput>
          </div>
          <div className="col-sm">
            <CustomInput>
              <label htmlFor="horario_fim">Horario de Fim </label>
              <input
                type="text"
                className={
                  errors.horario_fim ? "CustomInput error-input" : "CustomInput"
                }
                name="horario_fim"
                placeholder="11:30"
                ref={register({
                  required: true,
                  maxLength: 5,
                })}
                onChange={(e: any) => {
                  e.currentTarget.value = e.currentTarget.value
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d{0,2})/, "$1:$2")
                    .substring(0, 5);
                }}
              />
              {errors.horario_fim?.type === "required" && (
                <span className="error-message">Campo obrigatório</span>
              )}
              {errors.horario_fim?.type === "maxLength" && (
                <span className="error-message">Formato de hora inválido.</span>
              )}
            </CustomInput>
          </div>
        </div>

        <div
          className="row"
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        ></div>
        <div className="col-sm">
          <label htmlFor="dias">Percurso</label>

          <CustomReactSelect
            name="percurso"
            className="select"
            placeholder="Adicionar pontos"
            options={pontosOptions}
            onChange={handleSelectPonto}
            noOptionsMessage={() => "Nenhum ponto encontrado"}
          />
        </div>
        {fields.map((ponto, index) => (
          <>
            <div className="row" key={ponto.id}>
              <div className="col-1">
                <CustomInput>
                  <label htmlFor="index">#</label>
                  <input
                    type="text"
                    className="CustomInput"
                    name="index"
                    defaultValue={index + 1}
                    disabled
                  />
                </CustomInput>
              </div>
              <div className="col">
                <CustomInput>
                  <label htmlFor="index">Nome</label>
                  <input
                    type="text"
                    className="CustomInput"
                    name="index"
                    defaultValue={ponto.nome}
                    disabled
                  />
                </CustomInput>
              </div>
              <div className="col-4">
                <CustomInput>
                  <label htmlFor="index">Endereço</label>
                  <input
                    type="text"
                    className="CustomInput"
                    name="index"
                    defaultValue={ponto.endereco}
                    disabled
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <CustomInput>
                  <label htmlFor="index">Município</label>
                  <input
                    type="text"
                    className="CustomInput"
                    name="index"
                    defaultValue={ponto.municipio}
                    disabled
                  />
                </CustomInput>
              </div>
              <div className="col-sm">
                <RemoveButton
                  type="button"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <TiMinus /> Remover
                </RemoveButton>
              </div>
            </div>
            <></>
          </>
        ))}
      </FormContainer>
    </Container>
  );
};

export default CadastrarForm;
