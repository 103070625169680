import React, { useCallback, useEffect, useState } from "react";
import api from "./../../services/api";

import { GoPlus } from "react-icons/go";
import Button from "./../../template/components/Button";
import Layout from "./../../template/components/Layout";
import MainContainer from "./../../template/components/MainContainer";

import useToggle from "hooks/useToggle";
import { FiUsers } from "react-icons/fi";
import Loading from "../components/Loading";
import { GerenciarFuncionarioInterface } from "./../../interfaces/GetFuncionarioInterface";
import Pagination from "./../../template/components/Pagination";
import SearchBar from "./../../template/components/SearchBar";
import Table from "./../../template/components/Table";
import { dialogBox } from "./../../template/utils/dialogBox";
import { parseJsonToTableDataList } from "./../../template/utils/parser";
import CadastrarModal from "./components/CadastrarModal";
import DesabilitarModal from "./components/DesabilitarModal";
import DetalharModal from "./components/DetalharModal";
import HabilitarModal from "./components/HabilitarModal";
import {
  Container,
  CustomIconDesabilitar,
  CustomIconHabilitar,
  CustomReactSelect,
} from "./styles";

// Cabeçalho da tabela
const fields = [
  "Nome",
  "CPF",
  "Telefone",
  "E-mail",
  "Cargo",
  "Data de Expiração",
  "Ação",
];

const selectOptions2 = [
  { label: "Ativos", value: 2 },
  { label: "Inativos", value: 1 },
];

const GetFormatedDate = (date: Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + (d.getDate() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};

const GerenciarFuncionario = () => {
  const [gerenciarfuncionario, setGerenciarFuncionario] = useState<any[]>([]);
  const [opcaoExpiracao, setOpcaoExpiracao] = useState<Number>(2);
  const { value: modalDetalhar, toggle: toggleDetalhar } = useToggle();
  const { value: modalHabilitar, toggle: toggleHabilitar } = useToggle();
  const { value: modalDesabilitar, toggle: toggleDesabilitar } = useToggle();
  const { value: modalCadastrar, toggle: toggleCadastrar } = useToggle();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [searchedValue, setSearch] = useState<string>("");
  const [
    gerenciarfuncionarioEditar,
    setGerenciarFuncionarioEditar,
  ] = useState<GerenciarFuncionarioInterface>(Object);
  const [
    isLoadingGerenciarFuncionario,
    setIsLoadingGerenciarFuncionario,
  ] = useState(true);
  const [isLoadingDetalhar, setIsLoadingDetalhar] = useState(false);
  const itemsPerPage = 20;
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );

  const loadGerenciarFuncionario = useCallback(async () => {
    try {
      setIsLoadingGerenciarFuncionario(true);
      let ativo = true;
      if (opcaoExpiracao === 1) ativo = false;
      else ativo = true;

      const { data } = await api.get(
        `funcionarios-laticinios/?limit=${itemsPerPage}&offset=${offset}${
          isNaN(Number(searchedValue))
            ? `&search=${searchedValue}`
            : `&documento__numero__icontains=${searchedValue}`
        }&is_active=${ativo}&is_not_gestor_laticinio=true&ordering=-updated_at`
      );

      const { results, count } = data;
      setGerenciarFuncionario(
        parseJsonToTableDataList(parseGerenciarFuncionario(results), [
          "nome",
          "cpf",
          "telefone",
          "email",
          "cargo",
          "expiracao",
        ])
      );

      setTotal(count);
    } catch (error) {
      notify("error", "Erro no servidor");
    } finally {
      setIsLoadingGerenciarFuncionario(false);
    }
  }, [offset, searchedValue, opcaoExpiracao, notify]);

  useEffect(() => {
    loadGerenciarFuncionario();
  }, [loadGerenciarFuncionario]);

  // Parse Gestores
  function parseGerenciarFuncionario(gerenciarfuncionario: any[]) {
    let tmp = [];
    for (var i = 0; i < gerenciarfuncionario.length; i++) {
      const object = {
        id: gerenciarfuncionario[i].id,
        nome: gerenciarfuncionario[i]?.pessoa.nome,
        telefone: gerenciarfuncionario[i].pessoa.telefone
          ? gerenciarfuncionario[i].pessoa.telefone
          : "NÃO CADASTRADO",
        email: gerenciarfuncionario[i]?.usuario?.email
          ? gerenciarfuncionario[i].usuario.email
          : "NÃO CADASTRADO",
        cpf: gerenciarfuncionario[i]?.documento,
        expiracao: GetFormatedDate(gerenciarfuncionario[i]?.data_expiracao),
        cargo: gerenciarfuncionario[i]?.cargo,
      };
      tmp.push(object);
    }
    return tmp.sort();
  }

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  //Avança uma página
  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  const detalhar = async (row: any) => {
    toggleDetalhar();
    setIsLoadingDetalhar(true);
    const { data } = await api.get(`funcionarios-laticinios/${row.id}/`);
    setGerenciarFuncionarioEditar(data);
    setIsLoadingDetalhar(false);
  };

  const detalharHabilitar = async (row: any) => {
    setIsLoadingDetalhar(true);
    toggleHabilitar();
    const { data } = await api.get(`funcionarios-laticinios/${row.id}/`);
    setGerenciarFuncionarioEditar(data);
    setIsLoadingDetalhar(false);
  };

  const detalharDesabilitar = async (row: any) => {
    setIsLoadingDetalhar(true);
    toggleDesabilitar();
    const { data } = await api.get(`funcionarios-laticinios/${row.id}/`);
    setGerenciarFuncionarioEditar(data);
    setIsLoadingDetalhar(false);
  };

  return (
    <Layout>
      <MainContainer titlePage="Gerenciar Funcionários" iconPage={<FiUsers />}>
        <Container>
          {modalDetalhar && (
            <DetalharModal
              isOpen={modalDetalhar}
              toggle={toggleDetalhar}
              row={gerenciarfuncionarioEditar}
              loadGerenciarFuncionario={loadGerenciarFuncionario}
              isLoadingDetalhar={isLoadingDetalhar}
            />
          )}

          {modalHabilitar && (
            <HabilitarModal
              isOpen={modalHabilitar}
              toggle={toggleHabilitar}
              row={gerenciarfuncionarioEditar}
              loadGerenciarFuncionario={loadGerenciarFuncionario}
              setIsLoading={setIsLoadingDetalhar}
              isLoading={isLoadingDetalhar}
            />
          )}

          {modalDesabilitar && (
            <DesabilitarModal
              isOpen={modalDesabilitar}
              toggle={toggleDesabilitar}
              row={gerenciarfuncionarioEditar}
              loadGerenciarFuncionario={loadGerenciarFuncionario}
              setIsLoading={setIsLoadingDetalhar}
              isLoading={isLoadingDetalhar}
            />
          )}

          {modalCadastrar && (
            <CadastrarModal
              isOpen={modalCadastrar}
              toggle={toggleCadastrar}
              loadGerenciarFuncionario={loadGerenciarFuncionario}
            />
          )}
          <div className="row">
            <div>
              <Button
                color="green"
                name="Cadastrar Funcionário"
                iconButtom={<GoPlus />}
                onClick={toggleCadastrar}
              />
            </div>

            <CustomReactSelect
              name="expiracao"
              className="space"
              options={selectOptions2}
              defaultValue={{ label: "Ativos", value: 2 }}
              onChange={(e: any) => {
                setOpcaoExpiracao(e.value);
                first();
              }}
              noOptionsMessage={() => "Nenhum papel encontrado"}
            />

            <div className="searchBar space">
              <SearchBar placeholder="Nome ou CPF" submitHandler={search} />
            </div>
          </div>

          {isLoadingGerenciarFuncionario ? (
            <Loading />
          ) : (
            <Table
              fields={fields}
              rows={gerenciarfuncionario}
              hasSelection={false}
              hasSelectionAll={false}
              functions={[
                { name: "Editar", action: detalhar },
                opcaoExpiracao === 1
                  ? {
                      name: "Habilitar",
                      action: detalharHabilitar,
                      iconBtn: <CustomIconHabilitar />,
                    }
                  : {
                      name: "Desabilitar",
                      action: detalharDesabilitar,
                      iconBtn: <CustomIconDesabilitar />,
                    },
              ]}
            />
          )}
          <div className="containerFooterTable">
            Visualizando {gerenciarfuncionario.length} de um total de {total}{" "}
            registros
            <div className=""></div>
            <Pagination
              itemCount={total}
              itemsPerPage={itemsPerPage}
              selectedPage={page}
              handlePageChange={changePage}
              handleNextPage={next}
              handlePreviousPage={previous}
              handleLastPage={last}
              handleFirstPage={first}
              maxPages={5}
            />
          </div>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default GerenciarFuncionario;
