import React from "react";
import Card from "../Card";
import { CardTable } from "./styles";

type CardProps = {
  ponto: string;
  bovinoAvariado: number;
  caprinoAvariado: number;
  bovinoRecebimento: number;
  caprinoRecebimento: number;
};

function CardHome({
  ponto,
  bovinoAvariado,
  caprinoAvariado,
  bovinoRecebimento,
  caprinoRecebimento,
}: CardProps) {
  return (
    <Card title={ponto} heigth="auto">
      <CardTable>
        <tbody>
          <tr>
            <th></th>
            <th>Bovino</th>
            <th>Caprino</th>
            <th>Total</th>
          </tr>
          <tr>
            <th>Recebido</th>
            <td>{bovinoRecebimento}</td>
            <td>{caprinoRecebimento}</td>
            <td>{bovinoRecebimento + caprinoRecebimento}</td>
          </tr>
          <tr>
            <th>Avariado</th>
            <td>{bovinoAvariado}</td>
            <td>{caprinoAvariado}</td>
            <td>{bovinoAvariado + caprinoAvariado}</td>
          </tr>
        </tbody>
      </CardTable>
    </Card>
  );
}

export default CardHome;
