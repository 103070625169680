import styled from "styled-components";
import colors from "../../../../template/styles/colors";

export const CardTable = styled.table`
  border-collapse: collapse;

  td,
  th {
    border: 1px solid ${colors.lightGrey};
    padding: 5px;
    text-align: center;
  }
  tr:first-child th {
    border-top: 0;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
`;
