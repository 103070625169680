import styled from "styled-components";
import colors from "../../../styles/colors";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }
  .label-disabled{
    opacity: .7;
  }
  .white-check {
    color: white;
    width: 100px;
    height: 30px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    float: right;
  }
`;
