import React, { useState } from "react";
import Loading from "pages/components/Loading";
import Modal from "./../../../../template/components/Modal";
import {
  ButtonClose,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
  ButtonContentContainer,
} from "./styles";
import api from "services/api";
import { dialogBox } from "template/utils/dialogBox";
import { parseDate2 } from "template/utils/parser";
import { LoadingComponent } from "../../../components/Loading/styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadGerenciarFuncionario: () => void;
  setIsLoading: (value: boolean) => void;
  isLoading: boolean;
}

const notify = (type: string, message: string) => dialogBox(type, message);

const HabilitarModal = ({
  row,
  isOpen,
  toggle,
  loadGerenciarFuncionario,
  isLoading,
}: IProps) => {
  const [isLoadingHabilitar, setIsLoadingHabilitar] = useState(false);

  const handleHabilitarFuncionario = () => {
    setIsLoadingHabilitar(true);
    const atualDate = new Date();
    const updatedDate = {
      pessoa: {
        nome: row.pessoa.nome,
        data_nascimento: row.pessoa.data_nascimento,
      },
      usuario: {
        username: row.usuario.username,
        email: row.usuario.email,
      },
      data_expiracao: parseDate2(
        new Date(
          atualDate.getFullYear() + 2,
          atualDate.getMonth(),
          atualDate.getDate()
        )
      ),
      ativo: true,
    };

    api
      .patch(`/funcionarios-laticinios/${row.id}/`, updatedDate)
      .then(function () {
        // Notifica que houve sucesso na edição
        notify("success", `Funcionário desabilitado com sucesso!`);
        // Atualiza a tabela
        loadGerenciarFuncionario();
        setIsLoadingHabilitar(false);
        // Fecha o modal somente após a conclusão da requisição
        toggle();
      })
      .catch(function (error) {
        // Handle error here if the request fails
        console.error("Erro na requisição: ", error);
        setIsLoadingHabilitar(false);
      });
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose} disabled={isLoadingHabilitar}>
          <CustomAiOutlineClose /> Não
        </ButtonClose>
        <ButtonSave
          type="submit"
          onClick={handleHabilitarFuncionario}
          disabled={isLoadingHabilitar || isLoading}
        >
          <ButtonContentContainer>
            {isLoadingHabilitar ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Sim</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  const handleClose = () => {
    if (!isLoadingHabilitar) {
      toggle();
    }
  };

  return row ? (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={`Habilitar funcionário`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      {isLoading ? (
        <Loading
          heigthContainer="40px"
          heightLoading="20px"
          widthLoading="20px"
        />
      ) : (
        <>
          <h6>Você deseja habilitar {row.pessoa.nome}?</h6>
          <em>
            Observação: A data de expiração do funcionário irá ser prorrogada
            por dois anos.
          </em>
        </>
      )}
    </Modal>
  ) : (
    <div />
  );
};

export default HabilitarModal;
