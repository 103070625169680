import React, { useCallback } from "react";
import SelectRange from "./components/";
import {
  CustomPagination,
  CustomPaginationItem,
  CustomPaginationLink,
} from "./styles";
interface IProps {
  selectedPage: number;
  itemsPerPage: number;
  itemCount: number;
  maxPages: number;
  jumpToPage?: boolean;
  handlePageChange: (newPage: number) => void;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  handleLastPage?: (newPage: number) => void;
  handleFirstPage?: () => void;
}

const createPaginationButtons = (
  selectedPage: number,
  maxPages: number,
  pageCount: number,
  jumpToPage = false,
  clickHandler: (pageNumber: number) => void
) => {
  let items = [];
  let sidePages = Math.floor(maxPages / 2);
  for (let i = selectedPage - 1; i > 0 && i >= selectedPage - sidePages; i--) {
    items.push(
      <CustomPaginationItem>
        <CustomPaginationLink onClick={(event: Event) => clickHandler(i)}>
          {i}
        </CustomPaginationLink>
      </CustomPaginationItem>
    );
  }
  items.reverse();
  items.push(
    <CustomPaginationItem key={selectedPage}>
      <CustomPaginationLink className={"active"}>
        {selectedPage}
      </CustomPaginationLink>
    </CustomPaginationItem>
  );
  for (
    let i = selectedPage;
    i < pageCount && i < selectedPage + sidePages;
    i++
  ) {
    items.push(
      <CustomPaginationItem key={i + 1}>
        <CustomPaginationLink onClick={(event: Event) => clickHandler(i + 1)}>
          {i + 1}
        </CustomPaginationLink>
      </CustomPaginationItem>
    );
  }
  if (jumpToPage && pageCount > selectedPage + sidePages) {
    items.push(
      <SelectRange
        key="pageSelect"
        onChangeHandler={clickHandler}
        quantidade={pageCount}
      />
    );
    items.push(
      <CustomPaginationItem key={pageCount}>
        <CustomPaginationLink
          onClick={(event: Event) => clickHandler(pageCount)}
        >
          {pageCount}
        </CustomPaginationLink>
      </CustomPaginationItem>
    );
  }
  return items;
};

const Pagination: React.FC<IProps> = ({
  selectedPage,
  itemCount,
  maxPages,
  itemsPerPage,
  jumpToPage,
  handlePageChange,
  handleNextPage,
  handlePreviousPage,
  handleLastPage,
  handleFirstPage,
}) => {
  const pageClick = (page: number) => {
    handlePageChange(page);
  };
  const getPageCount = useCallback(() => {
    return Math.ceil(itemCount / itemsPerPage);
  }, [itemCount, itemsPerPage]);
  const defaultMaxPages = 5;
  maxPages = maxPages ? maxPages : defaultMaxPages;
  if (maxPages > 10) {
    maxPages = 10;
  }
  const pageCount = getPageCount();
  if (selectedPage > pageCount) {
    selectedPage = pageCount;
  } else if (selectedPage <= 0) {
    selectedPage = 1;
  }
  return (
    <CustomPagination>
      {selectedPage !== 1 ? (
        <>
          {handleFirstPage ? (
            <CustomPaginationItem>
              <CustomPaginationLink
                onClick={(event: Event) => {
                  event.preventDefault();
                  handleFirstPage();
                }}
              >
                {"Primeira"}
              </CustomPaginationLink>
            </CustomPaginationItem>
          ) : (
            ""
          )}
          <CustomPaginationItem>
            <CustomPaginationLink
              onClick={(event: Event) => {
                event.preventDefault();
                if (handlePreviousPage) {
                  handlePreviousPage();
                } else if (handlePageChange) {
                  handlePageChange(selectedPage - 1);
                }
              }}
            >
              {"Voltar"}
            </CustomPaginationLink>
          </CustomPaginationItem>
        </>
      ) : (
        ""
      )}

      {createPaginationButtons(
        selectedPage,
        maxPages,
        pageCount,
        jumpToPage,
        handlePageChange
      )}

      {selectedPage < pageCount ? (
        <>
          <CustomPaginationItem>
            <CustomPaginationLink
              onClick={(event: Event) => {
                event.preventDefault();
                if (handleNextPage) {
                  handleNextPage();
                } else {
                  pageClick(selectedPage + 1);
                }
              }}
            >
              {"Avançar"}
            </CustomPaginationLink>
          </CustomPaginationItem>
          {handleLastPage ? (
            <CustomPaginationItem>
              <CustomPaginationLink
                onClick={(event: Event) => {
                  event.preventDefault();
                  handleLastPage(pageCount);
                }}
              >
                {"Última"}
              </CustomPaginationLink>
            </CustomPaginationItem>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </CustomPagination>
  );
};
export default Pagination;
