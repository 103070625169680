import React from "react";
import { useHistory } from "react-router-dom";
import { History } from "history";
import { CustomBreadcrumb, CustomBreadcrumbItem, CustomLink } from "./styles";

interface IBreadCrumb {
  paths?: { [path: string]: string } | undefined;
}
const getHumanReadable = (
  path: string,
  pathsDict: { [path: string]: string } | undefined
) => {
  if (!pathsDict) {
    pathsDict = {};
  }
  let humanString: string;
  if (path in pathsDict) {
    humanString = pathsDict[path];
  } else if (path === "") {
    humanString = "Página Inicial";
  } else {
    humanString = path;
    while (humanString.includes("_") || humanString.includes("-")) {
      humanString = humanString.replace("-", " ");
      humanString = humanString.replace("_", " ");
    }
  }
  return humanString;
};
const getBreadCrumbsByPath = (
  history: History,
  keyValuePair: { [path: string]: string } | undefined
) => {
  let location = history.location.pathname.split("/");
  let breadcrumbs = location.map((path, index, location) => (
    <CustomBreadcrumbItem key={path}>
      <CustomLink active={index === location.length - 1} to={path}>
        {getHumanReadable(path, keyValuePair)}
      </CustomLink>
    </CustomBreadcrumbItem>
  ));
  return breadcrumbs;
};

const BreadCrumb = ({ paths }: IBreadCrumb) => {
  return (
    <CustomBreadcrumb>
      {getBreadCrumbsByPath(useHistory(), paths)}
    </CustomBreadcrumb>
  );
};

export default BreadCrumb;
