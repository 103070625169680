import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import brasaoRN from "../../../../template/assets/logo/logo_estado.png";
import colors from "../../../../template/styles/colors";

// Fonte
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

// Estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 11,
    padding: 2,
    alignItems: "center",
    color: "#FFF",
    backgroundColor: "#000",
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    margin: 0,
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    margin: 0,
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  typeTitle: {
    fontFamily: "Open Sans",
    fontWeight: 800,
    fontSize: 10,
    color: "#0E4F9F",
    marginBottom: 15,
  },
  customHeader: {
    marginTop: 0,
    display: "flex",
    paddingLeft: 5,
    backgroundColor: "black",
    color: "white",
    margin: 0,
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
  customHeaderText: {
    fontSize: 10,
    fontWeight: 600,
  },
  customCellText: {
    fontSize: 10,
  },
  customCell: {
    display: "flex",
    justifyContent: "center",
    paddingLeft: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  deliverySubTable: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  deliveryText: {
    fontSize: 10,
    justifyContent: "center",
    paddingLeft: 5,
    margin: 0,
    paddingTop: 5,
    paddingBottom: 5,
  },
  deliveryHeader: {
    width: "15%",
    backgroundColor: "#9DA5B1",
    color: "black",
  },
});

type DataTableValue = {
  [k: string]: string;
};

interface IProps {
  data: DataTableValue[];
  count: number;
  start: string;
  end: string;
  period: any;
  gestorLaticinioId: Number;
  totalValorBovino: Number | any;
  totalValorCaprino: Number | any;
  totalEntregueBovino: Number | any;
  totalEntregueCaprino: Number | any;
}

const RelatorioPDF = ({
  data,
  totalValorBovino,
  totalValorCaprino,
  totalEntregueBovino,
  totalEntregueCaprino,
}: IProps) => {
  const RenderDelivery = (entregas: any) =>
    entregas.map((item: any, index: number) => (
      <View key={index} style={styles.deliverySubTable}>
        <View style={[styles.deliveryText, { width: "35%" }]}>
          <Text style={{ marginLeft: 15 }}>{item.data}</Text>
        </View>
        <View style={[styles.deliveryText, { width: "15%" }]}>
          <Text>R${item.preco_leite_bovino}</Text>
        </View>
        <View style={[styles.deliveryText, { width: "15%" }]}>
          <Text>{item.quantidade_leite_bovino}</Text>
        </View>
        <View style={[styles.deliveryText, { width: "15%" }]}>
          <Text>R${item.preco_leite_caprino}</Text>
        </View>
        <View style={[styles.deliveryText, { width: "15%" }]}>
          <Text>{item.quantidade_leite_caprino}</Text>
        </View>
      </View>
    ));

  return (
    <>
      <Document title="Relatório de Entregas">
        <Page size="A4" orientation={"landscape"} style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.image} source={brasaoRN} />
          </View>
          <View style={styles.headerTextSection}>
            <Text style={styles.headerText}>
              GOVERNO DO ESTADO DO RIO GRANDE DO NORTE
            </Text>
            <Text style={styles.headerText}>
              SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
              SOCIAL - SETHAS
            </Text>
            <Text style={styles.headerText}>
              PROGRAMA DO LEITE - LEITE POTIGUAR
            </Text>
          </View>
          <View style={styles.titleTextSection}>
            <Text style={styles.typeTitle}>RELATÓRIO DE ENTREGAS DO LEITE</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: 0,
              paddingTop: 0,
              marginTop: 0,
            }}
          >
            <View style={[styles.customHeader, { width: "40%" }]}>
              <Text style={styles.customHeaderText}>Ponto</Text>
            </View>
            <View style={[styles.customHeader, { width: "15%" }]}>
              <Text style={styles.customHeaderText}>Cota Total Bovino</Text>
            </View>
            <View style={[styles.customHeader, { width: "15%" }]}>
              <Text style={styles.customHeaderText}>Total Entregue Bovino</Text>
            </View>
            <View style={[styles.customHeader, { width: "15%" }]}>
              <Text style={styles.customHeaderText}>Cota Total Caprino</Text>
            </View>
            <View style={[styles.customHeader, { width: "15%" }]}>
              <Text style={styles.customHeaderText}>
                Total Entregue Caprino
              </Text>
            </View>
          </View>
          {data.map((item, index) => (
            <View key={index}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: colors.darkGrey,
                }}
              >
                <View style={[styles.customCell, { width: "40%" }]}>
                  <Text style={styles.customCellText}>{item.ponto}</Text>
                </View>

                <View style={[styles.customCell, { width: "15%" }]}>
                  <Text style={styles.customCellText}>
                    {item.cota_leite_bovino}
                  </Text>
                </View>

                <View style={[styles.customCell, { width: "15%" }]}>
                  <Text style={styles.customCellText}>
                    {item.bovino_entregue}
                  </Text>
                </View>

                <View style={[styles.customCell, { width: "15%" }]}>
                  <Text style={styles.customCellText}>
                    {item.cota_leite_caprino}
                  </Text>
                </View>

                <View style={[styles.customCell, { width: "15%" }]}>
                  <Text style={styles.customCellText}>
                    {item.caprino_entregue}
                  </Text>
                </View>
              </View>
              {item.entregas.length ? (
                <>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={[
                        styles.customHeader,
                        {
                          width: "35%",
                          backgroundColor: "#9DA5B1",
                          color: "black",
                        },
                      ]}
                    >
                      <Text style={styles.customHeaderText}>Data</Text>
                    </View>
                    <View style={[styles.customHeader, styles.deliveryHeader]}>
                      <Text style={styles.customHeaderText}>
                        Preço Leite Bovino
                      </Text>
                    </View>
                    <View style={[styles.customHeader, styles.deliveryHeader]}>
                      <Text style={styles.customHeaderText}>
                        Entregue Bovino
                      </Text>
                    </View>
                    <View style={[styles.customHeader, styles.deliveryHeader]}>
                      <Text style={styles.customHeaderText}>
                        Preço Leite Caprino
                      </Text>
                    </View>
                    <View style={[styles.customHeader, styles.deliveryHeader]}>
                      <Text style={styles.customHeaderText}>
                        Entregue Caprino
                      </Text>
                    </View>
                  </View>
                  {RenderDelivery(item.entregas)}
                </>
              ) : null}
            </View>
          ))}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <View
              style={[
                styles.customHeader,
                { width: "15%", backgroundColor: "#1CA6E0" },
              ]}
            >
              <Text style={styles.customHeaderText}>Total Entregue Bovino</Text>
            </View>
            <View
              style={[
                styles.customHeader,
                { width: "10%", backgroundColor: "white", color: "black" },
              ]}
            >
              <Text style={styles.customHeaderText}>
                R${totalEntregueBovino}
              </Text>
            </View>

            <View
              style={[
                styles.customHeader,
                { width: "15%", backgroundColor: "#1CA6E0" },
              ]}
            >
              <Text style={styles.customHeaderText}>Total Bovino Preço</Text>
            </View>
            <View
              style={[
                styles.customHeader,
                { width: "10%", backgroundColor: "white", color: "black" },
              ]}
            >
              <Text style={styles.customHeaderText}>R${totalValorBovino}</Text>
            </View>

            <View
              style={[
                styles.customHeader,
                { width: "15%", backgroundColor: "#1CA6E0" },
              ]}
            >
              <Text style={styles.customHeaderText}>
                Total Entregue Caprino
              </Text>
            </View>
            <View
              style={[
                styles.customHeader,
                { width: "10%", backgroundColor: "white", color: "black" },
              ]}
            >
              <Text style={styles.customHeaderText}>
                R${totalEntregueCaprino}
              </Text>
            </View>

            <View
              style={[
                styles.customHeader,
                { width: "15%", backgroundColor: "#1CA6E0" },
              ]}
            >
              <Text style={styles.customHeaderText}>Total Caprino Preço</Text>
            </View>
            <View
              style={[
                styles.customHeader,
                { width: "10%", backgroundColor: "white", color: "black" },
              ]}
            >
              <Text style={styles.customHeaderText}>R${totalValorCaprino}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default RelatorioPDF;
