import { AiOutlineLoading3Quarters } from "react-icons/ai";
import styled, { keyframes } from "styled-components";

interface IContainer {
  height?: string;
}
interface ILoadingComponent {
  width?: string;
  heigth?: string;
}

const rotate = keyframes`
    from{
        transform: rotate(0deg);
    } 
    to{
        transform: rotate(360deg);
    }
`;

export const LoadingComponent = styled(
  AiOutlineLoading3Quarters
)<ILoadingComponent>`
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "40px"};
  animation: ${rotate} 4s linear infinite;
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height || "30vh"};
`;
