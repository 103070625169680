import styled from "styled-components";
import { RiLoginBoxLine } from "react-icons/ri";
//import backgroundImage from "../../assets/bg.png";

import colors from "../../styles/colors";

interface IProps {
  logo?: string;
  backGround?:string
}

export const Container = styled.div`
  background: url(${(props: IProps) => props.backGround});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoginCard = styled.div`
  margin-top: auto;
  background: white;
  border-radius: 10px;
  width: 20.5rem;
  height: 22rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 20px 30px;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      padding: 5px 8px;
      font-size: 16px;
      margin-bottom: 13px;
      border: 1px solid ${colors.darkGrey};
      border-radius: 4px;
    }

    .error-input {
      border: 2px solid ${colors.redError};
      box-shadow: 1px 1px 5px ${colors.redError};
      margin-bottom: 0;
    }
    .error-message {
      color: ${colors.redError};
      font-weight: bold;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: ${colors.darkGrey};
      }
    }

    button {
      margin-top: 0.5rem;
      border: none;
      padding: 7px 0;
      background: ${colors.blueOfficial};
      border-radius: 4px;
      color: #fff;
      width: 50%;
      font-size: 16px;
      :hover {
        cursor: pointer;
        background: ${colors.bluePLP};
      }
    }
  }
`;

export const Logo = styled.div`
  background-image: url(${(props: IProps) => props.logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 55%;
  margin-bottom: 10px;
`;

export const LoginFooter = styled.footer`
  margin-top: auto;

  div {
    text-align: center;
    p {
      color: ${colors.light};
      line-height: 0;
      text-shadow: 1px 2px 3px #000;
    }
  }
  img {
    width: 5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

const genericIcon = `
    font-size: 20px;
    color: #fff;
    margin-right: 15px;
    
`;

export const CustomRiLoginBoxLine = styled(RiLoginBoxLine)`
  ${genericIcon}
  margin-right: 10px;
`;
