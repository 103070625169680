import React, { useState } from "react";

import Modal from "./../../../../template/components/Modal";

import DetalharForm from "../DetalharForm";

import Loading from "pages/components/Loading";
import { LoadingComponent } from "../../../components/Loading/styles";
import {
  ButtonClose,
  ButtonContentContainer,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  row: any;
  isOpen: boolean;
  toggle: () => void;
  loadGerenciarFuncionario: () => void;
  isLoadingDetalhar: boolean;
}

const DetalharModal = ({
  row,
  isOpen,
  toggle,
  loadGerenciarFuncionario,
  isLoadingDetalhar,
}: IProps) => {
  const [isLoadingEditar, setIsLoadingEditar] = useState(false);

  const handleClose = () => {
    if (isLoadingEditar) return;
    toggle();
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="detalharForm"
          disabled={isLoadingDetalhar || isLoadingEditar}
        >
          <ButtonContentContainer>
            {isLoadingDetalhar || isLoadingEditar ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Salvar</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={
        isLoadingDetalhar
          ? "Carregando..."
          : `Editar informações de ${row.pessoa.nome}`
      }
      footerContent={footerButtons()}
      className="modal-lg"
    >
      {isLoadingDetalhar ? (
        <Loading />
      ) : (
        <DetalharForm
          row={row}
          setIsLoading={setIsLoadingEditar}
          toggle={toggle}
          loadGerenciarFuncionario={loadGerenciarFuncionario}
        />
      )}
    </Modal>
  );
};

export default DetalharModal;
