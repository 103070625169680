import React from 'react';
import { createGlobalStyle } from 'styled-components';

import {
	CustomModal,
	CustomModalHeader,
	CustomModalBody,
	CustomModalFooter
} from './styles';

interface IProps {
	isOpen: boolean;
	toggle: () => void;
	modalTitle: string;
	children: React.ReactNode;
	footerContent: React.ReactNode;
	className?: string;
	fixed?: boolean;
	size?: string;
	iconHeader?: React.ReactNode;
}

interface IStyle{
	modalOpen: boolean;
}

// Retira a barra de rolagem do body quando se abre um modal
const GlobalModalStyle = createGlobalStyle`
	html, body, #root{
		overflow: ${(props: IStyle) => props.modalOpen ? "hidden" : "auto"}!important;
	}
`;

const Modal: React.FC<IProps> = ({
	isOpen,
	toggle,
	modalTitle,
	children,
	footerContent,
	className,
	fixed,
	size,
	iconHeader
}) => {
	return (
		<CustomModal
			size={size ? size : ''}
			isOpen={isOpen}
			toggle={toggle}
			className={className}
			backdrop={fixed ? 'static' : true}
		>
			<GlobalModalStyle modalOpen={isOpen} />
			<CustomModalHeader toggle={toggle}>
				{iconHeader}
				{modalTitle}
			</CustomModalHeader>
			<CustomModalBody>{children}</CustomModalBody>
			<CustomModalFooter>{footerContent}</CustomModalFooter>
		</CustomModal>
	);
};

export default Modal;
