import React, { useCallback, useEffect, useState } from "react";

import { CardContainer, Container, SpinnerContainer, TelaCard } from "./styles";

import Spinner from "../../assets/icons/spinner.svg";

import Layout from "../../template/components/Layout";
import CardHome from "./Components/CardHome/CardHome";
import api from "services/api";
import { dialogBox } from "template/utils/dialogBox";
import SearchBar from "../../template/components/SearchBar";
import Pagination from "template/components/Pagination";
import MainContainer from "template/components/MainContainer";
import { FiUsers } from "react-icons/fi";

type EntregaType = {
  count: number;
  results: ResultsType[];
};

type ResultsType = {
  id: number;
  latitude: string;
  longitude: string;
  quantidade_leite_bovino: number;
  quantidade_leite_caprino: number;
  preco_leite_bovino: number;
  preco_leite_caprino: number;
  data?: string;
  gestor_laticinio: string;
  colaborador_ponto: string;
  ponto: string;
  laticinio: string;
  municipio: string;
  periodo: number;
  quantidade_leite_bovino_avariado: number;
  quantidade_leite_caprino_avariado: number;
  cpf_entregador: string;
  observacao_avaria: string;
  validade: string;
  num_lote: number;
};

const Dashboard: React.FC = () => {
  const notify = useCallback(
    (type: string, message: string) => dialogBox(type, message),
    []
  );
  const [entregas, setEntregas] = useState<EntregaType>({
    count: 0,
    results: [],
  });
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 12;
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchedValue, setSearch] = useState<string>("");

  useEffect(() => {
    async function getEntregas() {
      try {
        setIsLoading(true);

        const { data } = await api.get<EntregaType>(
          `entregas/get_card/?limit=${itemsPerPage}&offset=${offset}&search=${searchedValue}`
        );
        const { count } = data;

        setEntregas(data);

        setTotal(count);
      } catch (error) {
        notify("error", "Não foi possivel carregar os dados");
      }
      setIsLoading(false);
    }
    getEntregas();
  }, [offset, searchedValue, notify]);

  //Altera a página de acordo com o valor do botão escolhido do componente de paginação
  const changePage = useCallback((page: number) => {
    let newOffset = (page - 1) * itemsPerPage;
    setOffset(newOffset);
    setPage(page);
  }, []);

  const next = useCallback(() => {
    let newOffset = offset + itemsPerPage;
    let newPage = page + 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Volta uma página
  const previous = useCallback(() => {
    let newOffset = offset - itemsPerPage;
    let newPage = page - 1;
    setOffset(newOffset);
    setPage(newPage);
  }, [offset, page]);

  //Vai direto para a ultima página
  const last = useCallback(() => {
    setOffset(
      (total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1) *
        itemsPerPage -
        itemsPerPage
    );
    setPage(
      total % itemsPerPage === 0
        ? Math.floor(total / itemsPerPage)
        : Math.floor(total / itemsPerPage) + 1
    );
  }, [total]);

  //Vai direto para a primeira página
  const first = useCallback(() => {
    setOffset(0);
    setPage(1);
  }, []);

  // Função de busca por texto
  const search = useCallback((searchedValue: string) => {
    searchedValue = searchedValue.replace(/[.-]/g, "").trim();
    setSearch(searchedValue);
    setOffset(0);
    setPage(1);
  }, []);

  return (
    <Layout>
      <MainContainer
        iconPage={<FiUsers />}
        titlePage={"Lista de Distribuições e Entregas"}
      >
        <Container>
          <CardContainer>
            <span className="space"></span>
            {(entregas.results.length > 0 || searchedValue) && (
              <div className="searchBar space">
                <SearchBar
                  placeholder="Ponto ou Município"
                  submitHandler={search}
                />
              </div>
            )}
            {isLoading && (
              <SpinnerContainer>
                <img src={Spinner} alt="carregando" height={50} width={50} />
              </SpinnerContainer>
            )}

            <TelaCard>
              {!isLoading && (
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}
                >
                  {entregas &&
                    entregas.results.map((entregas) => (
                      <CardHome
                        key={entregas.id}
                        ponto={`Ponto ${entregas.ponto}`}
                        bovinoAvariado={
                          entregas.quantidade_leite_bovino_avariado
                        }
                        caprinoAvariado={
                          entregas.quantidade_leite_caprino_avariado
                        }
                        bovinoRecebimento={entregas.quantidade_leite_bovino}
                        caprinoRecebimento={entregas.quantidade_leite_caprino}
                      />
                    ))}
                </div>
              )}
              <div className="containerFooterTable">
                <p>
                  Visualizando {entregas.results.length} de um total de{" "}
                  {entregas.count} registros
                </p>

                <Pagination
                  itemCount={total}
                  itemsPerPage={itemsPerPage}
                  selectedPage={page}
                  handlePageChange={changePage}
                  handleNextPage={next}
                  handlePreviousPage={previous}
                  handleLastPage={last}
                  handleFirstPage={first}
                  maxPages={5}
                />
              </div>
            </TelaCard>
          </CardContainer>
        </Container>
      </MainContainer>
    </Layout>
  );
};

export default Dashboard;
