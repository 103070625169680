export default [
  {
    name: "Página inicial",
    icon: "CustomAiOutlineHome",
    route: "/dashboard",
    hasSubMenu: false,
  },
  // {
  //   name: "Listar famílias em espera",
  //   icon: "CustomFiUsers",
  //   route: "/vincular",
  //   hasSubMenu: false,
  // },
  // {
  //   name: "Cadastrar Funcionário",
  //   icon: "CustomGrGroup",
  //   route: "/cadastrar_funcionario",
  //   hasSubMenu: false,
  // },
  {
    name: "Prestar contas",
    icon: "CustomMdContentPaste",
    route: "/prestar_conta",
    hasSubMenu: false,
  },
  {
    name: "Rota de entrega",
    icon: "CustomRiTruckLine",
    route: "/rota_entrega",
    hasSubMenu: false,
  },
  // {
  //   name: "Laticínios",
  //   icon: "CustomRiTruckLine",
  //   route: "#",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       name: "op 1",
  //       route: "/confirmar",
  //     },
  //   ],
  // },
  {
    name: "Gerenciar",
    icon: "CustomGrGroup",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Funcionários",
        route: "/gerenciar_funcionario",
      },
      {
        name: "Produtores",
        route: "/gerenciar_produtores",
      },
    ],
  },
  {
    name: "Relatórios",
    icon: "CustomRiPagesLine",
    route: "#",
    hasSubMenu: true,
    subMenu: [
      {
        name: "Entregas do leite por Laticínio",
        route: "/relatorios/entregas_ponto",
      },
    ],
  },
  // {
  //   name: "Produtores",
  //   icon: "CustomGrUserWorker",
  //   route: "#",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       name: "op 1",
  //       route: "/confirmar",
  //     },
  //   ],
  // },
  // /* {
  //   name: "Relatórios",
  //   icon: "CustomRiPagesLine",
  //   route: "#",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       name: "Entregas do leite por Laticínio",
  //       route: "/relatorios/entregas/laticinio",
  //     },
  //   ],
  // },
];
