import React, { useMemo } from "react";

import Menu from "./components/Menu";

import { Container, SidebarBrand } from "./styles";

//import logoImage from "../../assets/logo/logo.png";
import logoImage from "../../assets/logo/logotest1.png";

interface IProps {
  isOpen: boolean;
  logoName?: string;
}

const Sidebar = ({ isOpen, logoName }: IProps) => {
  const logo = useMemo(() => {
    return logoName ? require(`../../assets/logo/${logoName}.png`) : logoImage;
  }, [logoName]);
  return (
    <Container isOpen={isOpen}>
      <SidebarBrand logo={logo} />
      <Menu />
    </Container>
  );
};

export default Sidebar;
