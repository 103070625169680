import styled from "styled-components";
import { AiOutlineHome } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { FaRegBuilding } from "react-icons/fa";
import { RiTruckLine, RiPagesLine, RiCommunityLine } from "react-icons/ri";
import { GrUserWorker, GrUserManager, GrGroup } from "react-icons/gr";
import { CgFileDocument } from "react-icons/cg";
import { IoMdBusiness } from "react-icons/io";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdContentPaste,
} from "react-icons/md";
import { Link } from "react-router-dom";
import colors from "../../../../styles/colors";

interface IProps {
  isOpen: Boolean;
}

export const Container = styled.li`
  background: ${(props: IProps) => (props.isOpen ? colors.lightGrey : "none")};
  list-style: none;
`;

export const SubMenu = styled.ul`
  display: ${(props: IProps) => (props.isOpen ? "block" : "none")};
  transition: display 0.5s;
`;

const genericItemStyle = `
    width: 100%;
    padding: 12px 0;
    cursor: pointer;
    :hover {
      background: ${colors.blueOfficial};
      color: #fff;
      a {
        color: #fff;
      }
    }
    position: relative;
`;

export const Item = styled.div`
  font-weight: ${(props: IProps) => (props.isOpen ? "bold" : "normal")};
  ${genericItemStyle};
`;

export const SubMenuItem = styled.li`
  ${genericItemStyle} list-style: none;
  padding-left: 45px;
`;

const genericIcon = `
    margin-left: 14px;
    margin-right: 14px;
    font-size: 20px;
    position: relative;
    bottom: 2px;
    color: ${colors.ultraDarkGrey};
    path {
      stroke: ${colors.ultraDarkGrey};
    } 
`;

export const CustomGrGroup = styled(GrGroup)`
  ${genericIcon}
`;

export const CustomAiOutlineHome = styled(AiOutlineHome)`
  ${genericIcon}
`;

export const CustomMdContentPaste = styled(MdContentPaste)`
  ${genericIcon}
`;

export const CustomFiUsers = styled(FiUsers)`
  ${genericIcon}
`;

export const CustomFaRegBuilding = styled(FaRegBuilding)`
  ${genericIcon}
`;

export const CustomRiTruckLine = styled(RiTruckLine)`
  ${genericIcon}
`;

export const CustomGrUserWorker = styled(GrUserWorker)`
  ${genericIcon}
`;

export const CustomRiPagesLine = styled(RiPagesLine)`
  ${genericIcon}
`;

export const CustomRiCommunityLine = styled(RiCommunityLine)`
  ${genericIcon}
`;

export const CustomGrUserManager = styled(GrUserManager)`
  ${genericIcon}
`;

export const CustomCgFileDocument = styled(CgFileDocument)`
  ${genericIcon}
`;

export const CustomIoMdBusiness = styled(IoMdBusiness)`
  ${genericIcon}
`;

export const CustomLink = styled(Link)`
  color: ${colors.ultraDarkGrey};
  :hover {
    text-decoration: none;
    background: ${colors.blueOfficial};
    color: #fff;
  }
`;

const genericArrowStyle = `
  font-size: 25px;
  position: absolute;
  right: 5px;
  top: 7px;
`;

export const CustomArrowDown = styled(MdKeyboardArrowDown)`
  ${genericArrowStyle}
`;

export const CustomArrowUp = styled(MdKeyboardArrowUp)`
  ${genericArrowStyle}
`;
