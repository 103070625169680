import React, { useState } from "react";

import CadastrarForm from "../CadastrarForm";
import Modal from "./../../../../template/components/Modal";

import { LoadingComponent } from "../../../components/Loading/styles";
import {
  ButtonClose,
  ButtonContentContainer,
  ButtonSave,
  CustomAiOutlineCheck,
  CustomAiOutlineClose,
} from "./styles";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  loadGerenciarFuncionario: () => void;
}

const CadastrarModal = ({
  isOpen,
  toggle,
  loadGerenciarFuncionario,
}: IProps) => {
  const [isLoadingCadastrar, setIsLoadingCadastrar] = useState(false);

  const handleClose = () => {
    if (isLoadingCadastrar) return;
    toggle();
  };

  const footerButtons = () => {
    return (
      <React.Fragment>
        <ButtonClose onClick={handleClose}>
          <CustomAiOutlineClose /> Fechar
        </ButtonClose>
        <ButtonSave
          type="submit"
          form="cadastrarForm"
          disabled={isLoadingCadastrar}
        >
          <ButtonContentContainer>
            {isLoadingCadastrar ? (
              <LoadingComponent height="10px" width="10px" />
            ) : (
              <CustomAiOutlineCheck />
            )}{" "}
            <span>Salvar</span>
          </ButtonContentContainer>
        </ButtonSave>
      </React.Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      fixed={true}
      modalTitle={`Cadastrar novo funcionário`}
      footerContent={footerButtons()}
      className="modal-lg"
    >
      <CadastrarForm
        setIsLoading={setIsLoadingCadastrar}
        toggle={handleClose}
        loadGerenciarFuncionario={loadGerenciarFuncionario}
      />
    </Modal>
  );
};

export default CadastrarModal;
