import styled from "styled-components";
import colors from "../../../../template/styles/colors";
import Select from "react-select";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  h6 {
    font-weight: bold;
  }

  label {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  input,
  select,
  textarea {
    margin-bottom: 13px;
    padding: 7px 2px;
    border: 1px solid ${colors.mediumGrey};
    border-radius: 2px;
  }

  .CheckBoxContainer-0 {
    height: auto;
    margin-top: 2rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-0 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-0 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .CheckBoxContainer-1 {
    height: auto;
    margin-top: 0.5rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-1 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-1 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .CheckBoxContainer-2 {
    height: auto;
    margin-top: 0.5rem;
    border: 1px solid ${colors.blueOfficial};
    border-radius: 5px;
    vertical-align: middle;
    :hover {
      border: 1px solid ${colors.ultraDarkGrey};
      cursor: pointer;
      box-shadow: 2px 2px 2px #888888;
    }
  }

  .CheckBoxContainer-2 input {
    opacity: 0;
    margin-left: 0.2rem;
    margin-top: 0.7rem;
  }

  .CheckBoxContainer-2 label {
    margin-left: 0.2rem;
    font-size: 16px;
  }

  .white-check {
    color: white;
    width: 100px;
    height: 30px;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    float: right;
  }
`;

export const RemoveButton = styled.button`
  border: 1px solid ${colors.mediumGrey};
  box-sizing: border-box;
  width: 90px;
  border-radius: 8px;
  margin-right: 10px;
  padding: 0 5px;
  min-height: 39px;
  font-size: 14px;
  vertical-align: middle;
  align-items: center;
  color: ${colors.light};
  background-color: ${colors.redError};
  :focus {
    outline: 0;
  }
  :hover {
    text-decoration: none;
    color: ${colors.ultraDarkGrey};
  }
`;

export const PlusButton = styled.button`
  font-size: 35px;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
  border: none;
  display: flex;
  color: ${colors.bluePLP};
  background-color: transparent;
  :hover {
    color: ${colors.blueOfficial};
  }
`;

export const CustomReactSelect = styled(Select)`
  width: 100%;
  margin-bottom: 13px;
`;
