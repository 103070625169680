import React from "react";
import Modal from "../../../../template/components/Modal";
import Table from "../../../../template/components/Table";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
  quinzena: String;
  entregas: any;
  year: number;
  ponto: string;
}
export const ModalEntregas = ({
  isOpen,
  toggle,
  className,
  quinzena,
  entregas,
  year,
  ponto,
}: IProps) => {
  //variaveis para teste
  const fields = ["Data", "Cota Bonino", "Cota Caprino"];

  //funcoes auxiliares
  const handleSetEntregas = () => {
    toggle();
  };
  //renderizacao
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => handleSetEntregas()}
      modalTitle={"Entregas do ponto " + ponto}
      footerContent={() => <h1>void</h1>}
      className={className}
      fixed={true}
    >
      <Table
        fields={fields}
        rows={entregas}
        hasSelection={false}
        hasSelectionAll={false}
      />
    </Modal>
  );
};
