import React, { useState, useMemo } from "react";

import {
  CustomAiOutlineHome,
  CustomFaRegBuilding,
  CustomFiUsers,
  CustomGrUserWorker,
  CustomRiTruckLine,
  CustomRiPagesLine,
  CustomLink,
  Container,
  SubMenu,
  Item,
  SubMenuItem,
  CustomArrowDown,
  CustomArrowUp,
  CustomCgFileDocument,
  CustomGrUserManager,
  CustomIoMdBusiness,
  CustomRiCommunityLine,
  CustomMdContentPaste,
  CustomGrGroup,
} from "./styles";

type SubMenuOption = {
  name: string;
  route: string;
};

type Option = {
  hasSubMenu: boolean;
  name: string;
  icon: string;
  route: string;
  subMenu?: SubMenuOption[];
};

interface IProps {
  option: Option;
}

const iconMap: any = {
  CustomAiOutlineHome: CustomAiOutlineHome,
  CustomFaRegBuilding: CustomFaRegBuilding,
  CustomFiUsers: CustomFiUsers,
  CustomGrUserWorker: CustomGrUserWorker,
  CustomRiTruckLine: CustomRiTruckLine,
  CustomRiPagesLine: CustomRiPagesLine,
  CustomCgFileDocument: CustomCgFileDocument,
  CustomGrUserManager: CustomGrUserManager,
  CustomIoMdBusiness: CustomIoMdBusiness,
  CustomRiCommunityLine: CustomRiCommunityLine,
  CustomMdContentPaste: CustomMdContentPaste,
  CustomGrGroup: CustomGrGroup,
};

const selectIcon = (iconName: string) => {
  if (iconMap[iconName]) {
    return React.createElement(iconMap[iconName], {});
  }
};

const MenuOption: React.FC<IProps> = ({ option }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const memoizedSelectIcon = useMemo(() => selectIcon(option.icon), [
    option.icon,
  ]);

  return (
    <Container isOpen={isOpen}>
      {option &&
        (option.hasSubMenu ? (
          <React.Fragment>
            <Item isOpen={isOpen} onClick={handleClick}>
              {!!option && memoizedSelectIcon}
              {option.name}
              {isOpen ? (
                <CustomArrowUp></CustomArrowUp>
              ) : (
                <CustomArrowDown></CustomArrowDown>
              )}
            </Item>
            <SubMenu isOpen={isOpen}>
              {option.subMenu?.map((item, idx) => (
                <CustomLink to={item.route}>
                  <SubMenuItem key={idx}>{item.name}</SubMenuItem>
                </CustomLink>
              ))}
            </SubMenu>
          </React.Fragment>
        ) : (
          <CustomLink to={option.route}>
            <Item isOpen={isOpen}>
              {!!option && memoizedSelectIcon}
              {option.name}
            </Item>
          </CustomLink>
        ))}
    </Container>
  );
};

export default MenuOption;
