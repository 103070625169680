import { AiOutlineCheckCircle } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import Select from "react-select";
import styled from "styled-components";
import colors from "./../../template/styles/colors";
export const Container = styled.div`
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    justify-content: flext-start;
    align-items: center;
  }

  .space {
    margin-left: 20px;
  }

  .space-row {
    margin-top: 18px;
  }

  .label {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
  }

  label {
    font-size: 13px;
    font-weight: bold;
    color: ${colors.bluePLP};
  }

  .searchBar {
    margin-right: 9px;
  }

  .containerFooterTable {
    display: flex;
    justify-content: space-between;
  }

  .error-input {
    border: 2px solid ${colors.redError};
    box-shadow: 1px 1px 5px ${colors.redError};
  }

  .error-message {
    color: ${colors.redError};
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
  }
`;

export const CustomReactSelect = styled(Select)`
  width: 200px;
`;

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 39,
    height: 39,
    minWidth: 200,
  }),

  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
    padding: "0 6px",
  }),

  input: (provided: any, state: any) => ({
    ...provided,
    margin: 0,
  }),

  indicatorSeparator: (state: any) => ({
    display: "none",
  }),

  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: 39,
  }),
};

export const CustomIconDesabilitar = styled(TiDeleteOutline)`
  font-size: 1.125rem;
  color: red;
`;

export const CustomIconHabilitar = styled(AiOutlineCheckCircle)`
  font-size: 1.125rem;
  color: green;
`;
