import styled from 'styled-components';
import colors from '../../styles/colors';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

export const CustomModal = styled(Modal)`
  box-shadow: 1px 2px 10px ${colors.ultraDarkGrey};
  border-radius: 12px !important;

  .modal-content {
    border-radius: 12px !important;
  }
  .modal-header {
    display: flex;
    align-items: center;
  }
  
  .modal-title {
    font-size: 14px;
    font-weight: bolder;  
  }
`;

export const CustomModalHeader = styled(ModalHeader)`
  background-color: ${colors.lightGrey};
  border-bottom: 1px solid ${colors.mediumGrey};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const CustomModalBody = styled(ModalBody)``;

export const CustomModalFooter = styled(ModalFooter)``;

const genericButton = `
    padding: 8px 20px;
    border-radius: 6px;
`;

export const ButtonSave = styled.button`
	${genericButton} background: ${colors.greenOfficial};
	border: none;
	color: #fff;
  :hover{
    background-color: ${colors.greenLightOfficial}
  }
`;

export const ButtonClose = styled.button`
  ${genericButton};
  border: 1px solid ${colors.mediumGrey};
`;

const genericIcon = `
    font-size: 15px;
    margin-right: 3px;
`;

export const CustomAiOutlineCheck = styled(AiOutlineCheck)`
  ${genericIcon}
`;

export const CustomAiOutlineClose = styled(AiOutlineClose)`
  ${genericIcon}
`;
