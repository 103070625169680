import styled from "styled-components";
import { GoSearch } from "react-icons/go";
import { BsPencil } from "react-icons/bs";
import colors from "../../../../../../template/styles/colors";

interface RowProps {
  isSelected: boolean;
}

export const TableRowContainer = styled.tr`
  background: ${(props: RowProps) =>
    props.isSelected ? colors.ultraLightGrey : "none"};
  .action-cell {
    width: 7.8rem;
  }
  td:last-child {
    border-right: 0;
  }
  td {
    border-right: 1px solid ${colors.darkGrey};
    vertical-align: middle;
  }
  th {
    vertical-align: middle;
    border-right: 1px solid ${colors.darkGrey};
  }
`;

export const TableButton = styled.button`
  background: #fff;
  border: 1px solid ${colors.blueOfficial};
  min-width: 6rem;
  border-radius: 4px;
  padding: 7px 4px;
  color: ${colors.blueOfficial};
  cursor: pointer;
  :hover {
    border: 1px solid ${colors.bluePLP};
    color: ${colors.bluePLP};
    .icon-button-table {
      color: ${colors.bluePLP};
    }
  }
`;

const genericIcon = `
	font-size: 1rem;
	color: ${colors.blueOfficial};
`;

export const CustomGoSearch = styled(GoSearch)`
  ${genericIcon}
`;

export const CustomBsPencil = styled(BsPencil)`
  ${genericIcon}
`;
