import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";

// Fonte
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src:
        "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600italic.ttf",
      fontStyle: "italic",
    },
  ],
});

// Estilos
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 40,
    paddingVertical: 20,
  },
  header: {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  image: {
    width: 70,
    height: 70,
    marginTop: 2,
  },
  headerTextSection: {
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "black",
  },
  titleTextSection: {
    alignItems: "center",
    marginTop: 20,
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  titleText: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
  },
  MealsCountSection: {
    flexDirection: "row",
    marginBottom: 10,
  },
  MealsCountText: {
    fontFamily: "Open Sans",
    fontSize: 10,
  },
  MealsCountTextValue: {
    fontFamily: "Open Sans",
    fontSize: 10,
    marginLeft: 5,
  },
  cell: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 11,
    padding: 2,
    color: "black",
    backgroundColor: "#cccccc",
  },
  cellData: {
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  cellDataMoney: {
    width: 200,
    fontFamily: "Open Sans",
    fontSize: 8,
    padding: 2,
    alignItems: "center",
    paddingTop: 5,
    paddingRight: 5,
    paddingBottom: 5,
    paddingLeft: 5,
  },
  currentDate: {
    fontFamily: "Open Sans",
    fontSize: 8,
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  typeTitle: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: 13,
    fontStyle: "italic",
    color: "black",
    marginBottom: 15,
  },
  bodyText: {
    fontFamily: "Open Sans",
    fontWeight: 500,
    fontSize: 10,
    textAlign: "justify",
  },
  space: {
    height: 20,
  },
  bodyFooter: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    textAlign: "center",
  },
  signView: {
    display: "flex",
    flexDirection: "row",
  },
  signEmpty: {
    flex: 1,
  },
  sign: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 10,
    borderTopWidth: 1,
    borderTopColor: "black",
    minWidth: 200,
  },
  fakeTable: {
    borderWidth: 1,
    borderColor: "black",
    width: 80,
    position: "absolute",
    right: 0,
  },
  fakeTableCell: {
    fontSize: 8,
  },
  fakeTableCellMid: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: "black",
    borderBottomColor: "black",
    fontSize: 8,
  },
  fakeTableText: {
    fontSize: 10,
  },
  descriptionRecept: {
    fontSize: 10,
    fontWeight: 600,
  },
  lign: {
    display: `flex`,
    alignItems: `flex-end`,
  },
  receptBody: {
    paddingLeft: 50,
  },
});

type DataTableValue = {
  [k: string]: string;
};

interface IProps {
  data: DataTableValue[];
  count: number;
  start: string;
  end: string;
}
export default function ReciboPDF({ data, count, start, end }: IProps) {
  return (
    <>
      <Document title="Requerimento">
        <Page size="A4" style={styles.page}>
          <View style={styles.headerTextSection}>
            <Text style={styles.headerText}>
              NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA - LEITE DO SERTÃO
            </Text>
            <Text style={styles.headerText}>
              CNPJ: 05777741000105 Insc. Estadual:
            </Text>
            <Text style={styles.headerText}>AV. SENADOR DUARTE FILHO, 450</Text>
            <Text style={styles.headerText}>
              RINCÃO - MOSSORO CEP: 59626100
            </Text>
            <Text style={styles.headerText}>
              Telefone: 3317-1599 Celular: 8839-3694
            </Text>
            <Text style={styles.headerText}>
              Email: raimundapriscilajoão2011@hotmail.com.br
            </Text>
            <View style={styles.fakeTable}>
              <View style={styles.fakeTableCell}>
                <Text style={styles.fakeTableText}>Órgão: SETHAS</Text>
              </View>
              <View style={styles.fakeTableCellMid}>
                <Text style={styles.fakeTableText}>Número:</Text>
              </View>
              <View style={styles.fakeTableCell}>
                <Text style={styles.fakeTableText}>Folha:</Text>
              </View>
            </View>
          </View>
          <View style={styles.space} />
          <View>
            <Text style={styles.bodyText}>
              &nbsp;&nbsp;SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA
              ASSISTÊNCIA SOCIAL
            </Text>
            <Text style={styles.bodyText}>Deve a</Text>
            <Text style={styles.bodyText}>
              &nbsp;&nbsp;&nbsp;&nbsp;NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA
            </Text>
            <Text style={styles.bodyText}>FATURA Nº: 44598-111/2010</Text>
          </View>
          <Table data={[...data]}>
            <TableHeader>
              <TableCell style={styles.cell}>Especificação</TableCell>
              <TableCell style={styles.cell}>Valor</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.cellData}
                getContent={(r) =>
                  "Fornecimento de 68815.00 litros de Leite BOVINO Pasteurizado Padronizado"
                }
              />
              <DataTableCell
                style={styles.cellDataMoney}
                getContent={(r) => "R$ 189241.25"}
              />
            </TableBody>
          </Table>
          <Text style={styles.descriptionRecept}>
            Importa presente fatura em R$ 189241.25 ( cento e oitenta e nove
            mil, duzentos e quarenta e um reais e vinte e cinco centavos).
          </Text>
          <View style={styles.space} />
          <View style={styles.space} />
          <Text style={styles.bodyFooter}>
            MOSSORO, 10 de Fevereiro de 2021
          </Text>
          <View style={styles.space} />
          <View style={styles.space} />
          <View style={styles.signView}>
            <View style={styles.signEmpty} />
            <Text style={styles.sign}>
              NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA
            </Text>
          </View>
          <View style={styles.titleTextSection}>
            <Text style={styles.typeTitle}>
              R&nbsp;E&nbsp;C&nbsp;I&nbsp;B&nbsp;O&nbsp;
            </Text>
          </View>
          <View style={styles.receptBody}>
            <Text style={styles.bodyText}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Recebi da
              SECRETARIA DE ESTADO DO TRABALHO, DA HABITAÇÃO E DA ASSISTÊNCIA
              SOCIAL, a importância de R$ 189241.25 ( cento e oitenta e nove
              mil, duzentos e quarenta e um reais e vinte e cinco centavos),
              referente ao fornecimento de 68815.00 litros de Leite BOVINO
              Pasteurizado Padronizado, no período de 16 a 31 de Janeiro de
              2021,Conforme NFE 44598.
            </Text>
            <Text style={styles.bodyText}>
              {" "}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do que, para fins
              e efeitos de direito, firmo o presente dando plena e geral
              quitação
            </Text>
          </View>

          <View style={styles.space} />
          <View style={styles.space} />
          <View style={styles.space} />
          <Text style={styles.bodyFooter}>
            MOSSORO, 10 de Fevereiro de 2021
          </Text>
          <View style={styles.space} />
          <View style={styles.space} />
          <View style={styles.signView}>
            <View style={styles.signEmpty} />
            <Text style={styles.sign}>
              NUTRIVIDA INDUSTRIA DE LATICINIOS LTDA
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
}
